import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import getOr from 'lodash/fp/getOr';

const MapSettingsItem = props => {
    const { onClick, activeIcon, inactiveIcon, isActive, className } = props;

    const activeIconClassNames = classNames(
        'hover-scale-110',
        isActive ? 'display-block' : 'display-none',
        getOr('', 'props.className')(activeIcon)
    );

    const inactiveIconClassNames = classNames(
        'hover-scale-110',
        isActive ? 'display-none' : 'display-block',
        getOr('', 'props.className')(inactiveIcon)
    );

    return (
        <div className={`MapSettingsItem ${className}`} onClick={onClick}>
            {activeIcon &&
                React.cloneElement(activeIcon, {
                    className: activeIconClassNames,
                })}
            {inactiveIcon &&
                React.cloneElement(inactiveIcon, {
                    className: inactiveIconClassNames,
                })}
        </div>
    );
};

MapSettingsItem.defaultProps = {
    isActive: false,
    className: '',
    onClick: () => {},
};

MapSettingsItem.propTypes = {
    activeIcon: PropTypes.node,
    inactiveIcon: PropTypes.node,
    isActive: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default MapSettingsItem;
