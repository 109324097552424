import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import mapProps from '../../../../utils/proptypes';
import { mapToLongLocale } from '../../../../utils/localization';

const BaseLayer = props => {
    const { api, createBaseLayer, language, showTraffic } = props;

    const [baseLayer, setBaseLayer] = useState();

    useEffect(() => {
        if (!baseLayer) {
            const lang = mapToLongLocale(language);
            const newBaseLayer = createBaseLayer(api, lang, showTraffic);

            setBaseLayer(newBaseLayer);
            setTimeout(() => api.map.setBaseLayer(newBaseLayer), 100);
        }
    });

    return null;
};

BaseLayer.propTypes = {
    api: mapProps.api,
    createBaseLayer: PropTypes.func.isRequired,
    showTraffic: PropTypes.bool,
    language: PropTypes.string,
};

export default BaseLayer;
