import React from 'react';

import { FORMAT, TILE_SIZE } from '../../../constants';
import BaseLayer from './BaseLayer';

const createSatelliteLayer = (api, language, showTraffic) => {
    const type = showTraffic ? 'traffic' : 'aerial';
    const tileType = showTraffic ? 'traffictile' : 'maptile';
    return api.platform
        .getMapTileService({ type })
        .createTileLayer(tileType, 'hybrid.day', TILE_SIZE, FORMAT, {
            style: 'default',
            lg: language,
        });
};

const SatelliteLayer = props => {
    return <BaseLayer {...props} createBaseLayer={createSatelliteLayer} />;
};

export default SatelliteLayer;
