/* eslint-disable no-undef */
import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { MapContext } from '../../context';
import MapException from '../../../exceptions/MapException';

import { useUpdateEventListenerMap } from '../../../utils/hooks';
import { removeEventListenerMap } from '../../../utils/eventHandling';

export const MISSING_GEOMETRY_PROPS_EXCEPTION_MSG = 'Error in Polyline: Polyline without geometry makes no sense';
export const WRONG_GEOMETRY_PROPS_EXCEPTION_MSG =
    'Error in Polyline: property geometry must be of type H.geo.LineString';

const Polyline = ({ geometry, hasArrows, arrowStyle, style = H.map.SpatialStyle.DEFAULT_STYLE, eventListenerMap }) => {
    const context = useContext(MapContext);
    const [polyline, setPolyline] = useState();

    if (!geometry) {
        throw new MapException(MISSING_GEOMETRY_PROPS_EXCEPTION_MSG);
    }

    if (!(geometry instanceof H.geo.LineString)) {
        throw new MapException(WRONG_GEOMETRY_PROPS_EXCEPTION_MSG);
    }

    useEffect(() => {
        const lineGroup = new H.map.Group();
        const line = new H.map.Polyline(geometry, { style });

        if (hasArrows) {
            // Create a patterned polyline:

            // Non-legacy version:
            const arrowLineStyle = new H.map.SpatialStyle({
                width: 5,
                lineWidth: 10,
                strokeColor: 'rgba(255, 255, 255, 0)',
            });
            const routeArrows = new H.map.Polyline(geometry, { style: arrowLineStyle });
            routeArrows.setArrows(arrowStyle);

            // create a group that represents the route line and contains
            // outline and the pattern
            lineGroup.addObjects([line, routeArrows]);
        } else {
            lineGroup.addObjects([line]);
        }

        context.group.addObject(lineGroup);
        setPolyline(lineGroup);

        return () => {
            // cleanup
            //console.log('cleanup polyline');

            removeEventListenerMap(lineGroup);
            lineGroup.forEach(singleLine => {
                lineGroup.removeObject(singleLine);
                singleLine.dispose();
            });

            if (context.group.getObjects().includes(lineGroup)) {
                context.group.removeObject(lineGroup);
            }
            lineGroup.dispose();
        };
    }, [geometry, style, hasArrows, arrowStyle]);

    /*useEffect(() => {
        if (polyline) {
            polyline.forEach((line, index) => {
                if (index === 0) {
                    line.setStyle(style);
                } else {
                    line.setStyle(arrowStyle);
                }
            });
        }
    }, [style, arrowStyle]);*/

    /*useEffect(() => {
        if (polyline) {
            polyline.forEach(line => line.setGeometry(geometry));
        }
    }, [geometry]);*/

    //useUpdateProp(polyline, 'setStyle', style);
    useUpdateEventListenerMap(polyline, eventListenerMap);

    return null;
};

Polyline.propTypes = {
    hasArrows: PropTypes.bool,
    arrowStyle: PropTypes.object,
    geometry: PropTypes.oneOfType([PropTypes.instanceOf(H.geo.LineString)]).isRequired,
    style: PropTypes.instanceOf(H.map.SpatialStyle),
    eventListenerMap: PropTypes.object,
};

export default Polyline;
