import React from 'react';
// import ReactDomServer from 'react-dom/server';
import PropTypes from 'prop-types';

import MapException from '../../../../exceptions/MapException';
import mapProps from '../../../../utils/proptypes';
import Marker from './Marker';

export const emptyTextException = 'Error in TextMarker: TextMarker without text makes no sense';
export const wrongTypeException = 'Error in TextMarker: property text must be string type';

class TextMarker extends React.PureComponent {
    render() {
        const { ...other } = this.props;

        // const textDiv = ReactDomServer.renderToStaticMarkup(
        //     <div style={{ border: '1px solid black', padding: '0 2px' }}>{this.getText()}</div>
        // );

        const textDiv = `<div>${this.getText()}</div>`;

        return <Marker {...other} icon={textDiv} />;
    }

    getText() {
        const { text } = this.props;
        if (!text) {
            throw new MapException(emptyTextException);
        }
        if (typeof text !== 'string') {
            throw new MapException(wrongTypeException);
        }
        return text;
    }
}

TextMarker.propTypes = {
    position: PropTypes.oneOfType([mapProps.position, mapProps.positions]).isRequired,
    text: PropTypes.string.isRequired,
};

export default TextMarker;
