export const MAP_TYPE_DEFAULT = 'DEFAULT';
export const MAP_TYPE_FLEET_STYLE = 'FLEET_STYLE';
export const MAP_TYPE_SATELLITE = 'SATELLITE';
export const MAP_TYPE_TERRAIN = 'TERRAIN';

export const MAP_LAYER_INCIDENTS = 'INCIDENTS';
export const MAP_LAYER_TRAFFIC = 'TRAFFIC';
export const MAP_LAYER_ROAD_RESTRICTIONS = 'ROAD_RESTRICTIONS';

export const FORMAT = 'png8';
export const TILE_SIZE = 256;
export const TILE_TYPE = 'maptile';

export const HIGH_RES_PPI = 320;
