/* eslint-disable camelcase */

import React from 'react';

import { FORMAT, TILE_SIZE } from '../../constants';
import Layer from './Layer';

const createTruckLayer = (api, language) => {
    return api.platform
        .getMapTileService({ type: 'base' })
        .createTileLayer('truckonlytile', 'normal.day', TILE_SIZE, FORMAT, {
            style: 'default',
            lg: language,
        });
};

const TruckLayer = props => <Layer {...props} createLayer={createTruckLayer} />;

export default TruckLayer;
