import React from 'react';
import PropTypes from 'prop-types';

import MapSettingsPanel from '../MapSettingsPanel';
import MapSettingsItem from '../MapSettingsItem';
import MapSettingsTile from '../MapSettingsTile';

import {
    MapIcon,
    CLUSTER_ACTIVE,
    CLUSTER_INACTIVE,
    NO_CLUSTER_ACTIVE,
    NO_CLUSTER_INACTIVE,
} from '../../../../icons/MapIcon';

const MapClusterSettings = props => {
    const { showCluster, onShowClusterChange, onToggle, ...remainingProps } = props;

    const handleShowCluster = setShowCluster => {
        onShowClusterChange(setShowCluster);
        onToggle(setShowCluster);
    };

    return (
        <MapSettingsTile
            className='MapClusterSettings'
            isActive={showCluster}
            activeIcon={<MapIcon name={CLUSTER_INACTIVE} />}
            inactiveIcon={<MapIcon name={NO_CLUSTER_INACTIVE} />}
            panel={
                <MapSettingsPanel>
                    <MapSettingsItem
                        onClick={() => handleShowCluster(true)}
                        isActive={showCluster}
                        activeIcon={<MapIcon name={CLUSTER_ACTIVE} className='MapClusterIcon' />}
                        inactiveIcon={<MapIcon name={CLUSTER_INACTIVE} className='MapClusterIconInactive' />}
                    />
                    <MapSettingsItem
                        onClick={() => handleShowCluster(false)}
                        isActive={!showCluster}
                        activeIcon={<MapIcon name={CLUSTER_ACTIVE} className='MapNoClusterIcon' />}
                        inactiveIcon={<MapIcon name={NO_CLUSTER_INACTIVE} className='MapNoClusterIconInactive' />}
                    />
                </MapSettingsPanel>
            }
            {...remainingProps}
        />
    );
};

MapClusterSettings.displayName = 'MapClusterSettings';

MapClusterSettings.defaultProps = {
    onShowClusterChange: () => {},
    showCluster: true,
    onToggle: () => {},
};

MapClusterSettings.propTypes = {
    onShowClusterChange: PropTypes.func,
    showCluster: PropTypes.bool,
    onToggle: PropTypes.func,
};

export default MapClusterSettings;
