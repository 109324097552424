import React from 'react';
import PropTypes from 'prop-types';

import MapSettingsItem from '../MapSettingsItem';

import { MapIcon, WORKSHOP_POIS_ACTIVE, WORKSHOP_POIS_INACTIVE } from '../../../../icons/MapIcon';

const MapWorkshopPoiSettingsItem = props => {
    const { isActive, onToggle, ...remainingProps } = props;

    const handleToggle = setIsShown => onToggle(setIsShown);

    return (
        <MapSettingsItem
            className='MapWorkshopPoi'
            onClick={() => handleToggle(false)}
            isActive={isActive}
            activeIcon={<MapIcon name={WORKSHOP_POIS_ACTIVE} className='MapPoiIcon' />}
            inactiveIcon={<MapIcon name={WORKSHOP_POIS_INACTIVE} className='MapPoiIconInactive' />}
            {...remainingProps}
        />
    );
};

MapWorkshopPoiSettingsItem.displayName = 'MapWorkshopPoiSettingsItem';

MapWorkshopPoiSettingsItem.defaultProps = {
    isActive: false,
    onToggle: () => {},
};

MapWorkshopPoiSettingsItem.propTypes = {
    isActive: PropTypes.bool,
    onToggle: PropTypes.func,
};

export default MapWorkshopPoiSettingsItem;
