import React from 'react';
import PropTypes from 'prop-types';

import { MAP_LAYER_INCIDENTS } from '../../../constants';
import MapSettingsItem from '../MapSettingsItem';

import { MapIcon, CONSTRUCTION_ACTIVE, CONSTRUCTION_INACTIVE } from '../../../../icons/MapIcon';

const MapLayerIncidentsItem = props => {
    const { activeBuiltinLayers, onMapLayerChange, onToggle, ...remainingProps } = props;

    const handleToggle = () => {
        onMapLayerChange(MAP_LAYER_INCIDENTS);
        onToggle();
    };

    return (
        <MapSettingsItem
            className='MapLayerIncidents'
            onClick={handleToggle}
            isActive={activeBuiltinLayers.includes(MAP_LAYER_INCIDENTS)}
            activeIcon={<MapIcon name={CONSTRUCTION_ACTIVE} />}
            inactiveIcon={<MapIcon name={CONSTRUCTION_INACTIVE} />}
            {...remainingProps}
        />
    );
};

MapLayerIncidentsItem.displayName = 'MapLayerIncidentsItem';

MapLayerIncidentsItem.defaultProps = {
    activeBuiltinLayers: [],
    onMapLayerChange: () => {},
    onToggle: () => {},
};

MapLayerIncidentsItem.propTypes = {
    activeBuiltinLayers: PropTypes.array,
    onMapLayerChange: PropTypes.func,
    onToggle: PropTypes.func,
};

export default MapLayerIncidentsItem;
