import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getIcons = iconNames => {
    return iconNames.map((name, index) => (
        <div key={`rio-map-icon-${name}-${index}`} className={`rio-map-icon rioglyph rioglyph-${name}`}></div>
    ));
};

const getColorMapping = (colorClass = '', markerColor) => {
    switch (colorClass) {
        case 'color-a':
            return 'bg-map-marker-asset';
        case 'color-b':
            return 'bg-map-marker-geofence';
        case 'color-c':
            return 'bg-map-marker-poi';
        default:
            return markerColor;
    }
};

const getMapDirection = (moving, bearing, rotationStyle) =>
    moving && typeof bearing === 'number' ? <div className='rio-map-direction' style={rotationStyle}></div> : '';

const SingleMapMarker = props => {
    const {
        active,
        bearing,
        colorClass,
        exceptionCount,
        iconNames,
        markerColor,
        moving,
        clickable,
        name,
        textColor,
        warningCount,
        fixed,
    } = props;

    if (colorClass) {
        // warnung
    }

    const classes = classNames(active && 'active', 'rio-map-marker', 'rio-map-marker-bottom-center');

    const singleClasses = classNames(
        'rio-map-single',
        fixed && 'fixed',
        moving && 'moving',
        !clickable && 'pointer-events-none',
        textColor
    );

    const rotationStyle = {
        transform: `rotate(${bearing}deg)`,
    };

    const mapDirection = getMapDirection(moving, bearing, rotationStyle);

    const baseColor = getColorMapping(colorClass, markerColor);

    const markerBackgroundColor = active ? 'bg-white' : baseColor;

    const markerBorderColor = baseColor.replace('bg-', 'border-color-');

    const markerTextColor = active ? baseColor.replace('bg-', 'text-color-') : 'text-color-white';

    return (
        <div className={classes}>
            <div className={`rio-map-marker-translate`}>
                <div className={`${singleClasses} ${markerBackgroundColor} ${markerBorderColor} ${markerTextColor}`}>
                    {mapDirection}
                    {iconNames && getIcons(iconNames)}
                    <div className='rio-map-name'>{name && name}</div>
                    <div className='rio-map-bubble exception'>{exceptionCount > 0 && exceptionCount}</div>
                    <div className='rio-map-bubble warning'>{warningCount > 0 && warningCount}</div>
                </div>
                <div className={`rio-map-position-arrow ${baseColor}`}></div>
                <div className={`rio-map-position ${markerBorderColor}`}></div>
            </div>
        </div>
    );
};

SingleMapMarker.defaultProps = {
    warningCount: 0,
    exceptionCount: 0,
    active: false,
    markerColor: 'bg-map-marker-asset',
    clickable: true,
};

SingleMapMarker.propTypes = {
    bearing: PropTypes.number,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    warningCount: PropTypes.number,
    exceptionCount: PropTypes.number,
    active: PropTypes.bool,
    fixed: PropTypes.bool,
    clickable: PropTypes.bool,
    iconNames: PropTypes.arrayOf(PropTypes.string),
    colorClass: PropTypes.string,
    moving: PropTypes.bool,
    markerColor: PropTypes.string,
};

export default SingleMapMarker;
