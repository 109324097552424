import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getColorMapping = (colorClass = '') => {
    switch (colorClass) {
        case 'color-a':
            return 'bg-map-marker-asset';
        case 'color-b':
            return 'bg-map-marker-geofence';
        case 'color-c':
            return 'bg-map-marker-poi';
        default:
            return null;
    }
};

const ClusterMapMarker = props => {
    const { colorClass, count, exceptionCount, markerColor, warningCount, active, clickable, iconName } = props;

    const classes = classNames(
        active && 'active',
        'rio-map-marker',
        'rio-map-marker-center-center',
        !clickable && 'pointer-events-none'
    );

    const clusterClasses = classNames('rio-map-cluster-cirlce', colorClass);

    const baseColor = getColorMapping(colorClass) || markerColor;

    const markerBackgroundColor = active ? 'bg-white' : baseColor;

    const markerBorderColor = baseColor.replace('bg-', 'border-color-');

    const markerTextColor = active ? baseColor.replace('bg-', 'text-color-') : 'text-color-white';

    return (
        <div className={classes}>
            <div className={`rio-map-marker-translate`}>
                <div className={'rio-map-cluster'}>
                    <div
                        className={`${clusterClasses} ${markerBackgroundColor} ${markerBorderColor} ${markerTextColor}`}
                    >
                        <div className={'rio-map-cluster-content'}>
                            {iconName && <div className={`rio-map-icon rioglyph rioglyph-${iconName}`} />}
                            <div className={'rio-map-count'}>{count}</div>
                        </div>
                    </div>
                    <div className={'rio-map-bubble exception'}>{exceptionCount > 0 && exceptionCount}</div>
                    <div className={'rio-map-bubble warning'}>{warningCount > 0 && warningCount}</div>
                </div>
            </div>
        </div>
    );
};

ClusterMapMarker.defaultProps = {
    warningCount: 0,
    exceptionCount: 0,
    active: false,
    markerColor: 'bg-map-marker-asset',
    clickable: true,
};

ClusterMapMarker.propTypes = {
    count: PropTypes.number,
    warningCount: PropTypes.number,
    exceptionCount: PropTypes.number,
    clickable: PropTypes.bool,
    active: PropTypes.bool,
    colorClass: PropTypes.string,
    markerColor: PropTypes.string,
    iconName: PropTypes.string,
};

export default ClusterMapMarker;
