import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import uniqueId from 'lodash/fp/uniqueId';

import { getKeyOrDefault } from '../../utils/validation';
import { MapSettingsContext } from '../context';

import MapTypeSettings from './settings/builtinSettings/MapTypeSettings';
import MapLayerSettings from './settings/builtinSettings/MapLayerSettings';
import MapClusterSettings from './settings/builtinSettings/MapClusterSettings';

const getDisplayname = get('type.displayName');

const isMapTypeSettings = option => option.type === MapTypeSettings;
const isMapLayerSettings = option => option.type === MapLayerSettings;
const isMapClusterSettings = option => option.type === MapClusterSettings;

const MapSettings = props => {
    const settingsContext = useContext(MapSettingsContext);

    const {
        mapType,
        activeBuiltinLayers,
        showCluster,
        onMapTypeChange,
        onMapLayerChange,
        onShowClusterChange,
    } = settingsContext;

    const { options, className } = props;

    const mapSettingsClasses = classNames('MapSettingsWrapper', className);

    return (
        <div className={mapSettingsClasses}>
            {map(option => {
                if (isMapTypeSettings(option)) {
                    return React.cloneElement(option, {
                        key: getKeyOrDefault(getDisplayname(option), option),
                        mapType,
                        onMapTypeChange,
                    });
                }

                if (isMapLayerSettings(option)) {
                    return React.cloneElement(option, {
                        key: getKeyOrDefault(getDisplayname(option), option),
                        activeBuiltinLayers,
                        onMapLayerChange,
                    });
                }

                if (isMapClusterSettings(option)) {
                    return React.cloneElement(option, {
                        key: getKeyOrDefault(getDisplayname(option), option),
                        showCluster,
                        onShowClusterChange,
                    });
                }

                return React.cloneElement(option, {
                    key: getKeyOrDefault(uniqueId('MapSettingsOption_'), option),
                });
            }, options)}
        </div>
    );
};

MapSettings.defaultProps = {
    showCluster: true,
    className: '',
};

MapSettings.propTypes = {
    onMapTypeChange: PropTypes.func,
    onMapLayerChange: PropTypes.func,
    onShowClusterChange: PropTypes.func,
    mapType: PropTypes.string,
    activeBuiltinLayers: PropTypes.arrayOf(PropTypes.string),
    showCluster: PropTypes.bool,
    mapSettingsTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    options: PropTypes.array,
    className: PropTypes.string,
};

export default MapSettings;
