import getOr from 'lodash/fp/getOr';

import MapException from '../exceptions/MapException';

export const validateMinMaxZoomUnchanged = (props, nextProps) => {
    if (props.minZoom !== nextProps.minZoom || props.maxZoom !== nextProps.maxZoom) {
        throw new MapException('rio-map currently does not support changing of minZoom/maxZoom property.');
    }
};

export const getKeyOrDefault = (defaultValue, object) => getOr(defaultValue, 'props.key')(object);
