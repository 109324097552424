import React from 'react';
import PropTypes from 'prop-types';

import MapSettingsTile from '../MapSettingsTile';

import { MapIcon, CENTER_MARKER_ACTIVE, CENTER_MARKER_INACTIVE } from '../../../../icons/MapIcon';

const MapCenterMarkerButton = props => {
    const { isActive, onToggle, ...remainingProps } = props;

    return (
        <MapSettingsTile
            className='MapCenterMarkerButton'
            isActive={isActive}
            activeIcon={<MapIcon name={CENTER_MARKER_ACTIVE} className='MapCenterMarkerButtonIcon' />}
            inactiveIcon={<MapIcon name={CENTER_MARKER_INACTIVE} className='MapCenterMarkerButtonIconInactive' />}
            onToggle={onToggle}
            {...remainingProps}
        />
    );
};

MapCenterMarkerButton.displayName = 'MapCenterMarkerButton';

MapCenterMarkerButton.defaultProps = {
    isActive: false,
};

MapCenterMarkerButton.propTypes = {
    onToggle: PropTypes.func,
    isActive: PropTypes.bool,
};

export default MapCenterMarkerButton;
