/* eslint-disable no-use-before-define */
export const EventUtils = {
    MOUSE_BUTTONS: {
        LEFT: 0,
        MIDDLE: 1,
        RIGHT: 2,
    },
    POINTER_DOWN: 'pointerdown',
    POINTER_UP: 'pointerup',
    POINTER_MOVE: 'pointermove',
    POINTER_ENTER: 'pointerenter',
    POINTER_LEAVE: 'pointerleave',
    POINTER_CANCEL: 'pointercancel',
    DRAG_START: 'dragstart',
    DRAG: 'drag',
    DRAG_END: 'dragend',
    TAP: 'tap',
    DBL_TAP: 'dbltap',
    LONGPRESS: 'longpress',
    CONTEXTMENU: 'contextmenu',
    CONTEXTMENU_CLOSE: 'contextmenuclose',
    MAP_VIEW_CHANGE_START: 'mapviewchangestart',
    MAP_VIEW_CHANGE: 'mapviewchange',
    MAP_VIEW_CHANGE_END: 'mapviewchangeend',
    BASE_LAYER_CHANGE: 'baselayerchange',
    ENGINE_CHANGE: 'enginechange',
    createTapOnDblTapPreventer,
    getMouseButton,
    isRightClick,
};

class MapTimeGuard {
    constructor(delay = 250) {
        this.delayTime = delay;
        this.timer = undefined;
    }

    delay(callback, ...args) {
        this.cancel();
        this.timer = window.setTimeout(() => callback(...args), this.delayTime);
    }

    cancel() {
        window.clearTimeout(this.timer);
    }
}

class EnhancedMapEvent {
    constructor(event, mapApi) {
        this.viewportX = event.currentPointer.viewportX;
        this.viewportY = event.currentPointer.viewportY;
        this.map = mapApi;
    }

    calculateGeoCoords() {
        return this.map.screenToGeo(this.viewportX, this.viewportY);
    }
}

export function createEnhancedMapEvent(event, mapApi) {
    if (!event || !event.currentPointer) {
        return {
            map: mapApi,
        };
    }
    return new EnhancedMapEvent(event, mapApi);
}

export function createEnhancedListener(listener, mapApi) {
    return event => listener(event, createEnhancedMapEvent(event, mapApi));
}

export function addEventListenerMap(hMapObject, eventListenerMap = {}, mapApi) {
    const enhancedMap = {};

    Object.keys(eventListenerMap).forEach(key => {
        const enhancedListener = createEnhancedListener(eventListenerMap[key], mapApi);
        hMapObject.addEventListener(key, enhancedListener);
        enhancedMap[key] = enhancedListener;
    });

    hMapObject.rioMapRemoveEvents = () => {
        Object.keys(enhancedMap).forEach(key => {
            hMapObject.removeEventListener(key, enhancedMap[key]);
        });
        delete hMapObject.rioMapRemoveEvents;
        return mapApi;
    };
}

export function removeEventListenerMap(hMapObject) {
    if (hMapObject.rioMapRemoveEvents) {
        return hMapObject.rioMapRemoveEvents();
    }
}

export const checkAndUpdateEventListenerMap = (hMapObject, props, nextProps) => {
    if (!hMapObject) {
        return;
    }
    if (props.eventListenerMap !== nextProps.eventListenerMap) {
        // use exports to make methods accessible for stubbing
        const mapApi = removeEventListenerMap(hMapObject);
        addEventListenerMap(hMapObject, nextProps.eventListenerMap, mapApi);
    }
};

export function createTapOnDblTapPreventer(eventListenerMap, delayBetweenTaps = 300) {
    const result = Object.assign({}, eventListenerMap);
    if (!eventListenerMap[EventUtils.TAP] || !eventListenerMap[EventUtils.DBL_TAP]) {
        return result;
    }

    const guard = new MapTimeGuard(delayBetweenTaps);

    result[EventUtils.TAP] = function (...args) {
        guard.delay(eventListenerMap[EventUtils.TAP], ...args);
    };
    result[EventUtils.DBL_TAP] = function (...args) {
        guard.cancel();
        eventListenerMap[EventUtils.DBL_TAP](...args);
    };

    return result;
}

export function getMouseButton(event) {
    return event.originalEvent.button;
}

export function isRightClick(event) {
    return getMouseButton(event) === EventUtils.MOUSE_BUTTONS.RIGHT;
}
