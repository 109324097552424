import { useEffect } from 'react';

import PropTypes from 'prop-types';
import mapProps from '../../../utils/proptypes';
import { mapToLongLocale } from '../../../utils/localization';

const Layer = props => {
    const { api, createLayer, layerIndex, language } = props;

    useEffect(() => {
        const lang = mapToLongLocale(language);
        const newLayer = createLayer(api, lang);
        api.map.addLayer(newLayer, layerIndex);

        return () => {
            if (newLayer) {
                api.map.removeLayer(newLayer);
                newLayer.dispose();
            }
        };
    }, []);

    return null;
};

Layer.propTypes = {
    api: mapProps.api,
    createLayer: PropTypes.func.isRequired,
    language: PropTypes.string,
    layerIndex: PropTypes.number,
};

export default Layer;
