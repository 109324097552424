/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import mapProps from '../../utils/proptypes';
import isEmpty from 'lodash/fp/isEmpty';
import isArray from 'lodash/fp/isArray';

import Polyline from './basics/Polyline';
import Marker from './basics/marker/Marker';
import MapException from '../../exceptions/MapException';

export const MISSING_POSITIONS_EXCEPTION_MSG = 'Route requires a minimum of 1 positions';

export const TO_LESS_LINE_WIDTH_EXCEPTION_MSG =
    'If isBorderIncludedInWidth is set to true than width has to be greater than twice of borderWidth';

const LINE_STROKE_COLOR = 'rgba(48, 194, 255, 1)';
const LINE_STROKE_COLOR_REDUCED = 'rgba(48, 194, 255, 0.5)';

const BORDER_STROKE_COLOR = 'rgba(255, 255, 255, 1)';
const BORDER_STROKE_COLOR_REDUCED = 'rgba(255, 255, 255, 0)';

const defaultStyle = {
    width: 5,
    color: LINE_STROKE_COLOR,
    borderWidth: 2,
    borderColor: 'rgba(54, 144, 174, 1)',
    isBorderIncludedInWidth: false,
};

const defaultArrowStyle = {
    lineWidth: 10,
    fillColor: 'rgba(42, 55, 64, 1)',
    strokeColor: BORDER_STROKE_COLOR,
    lineDash: [0, 6],
    lineTailCap: 'arrow-tail',
    lineHeadCap: 'arrow-head',
};

const alternativeRouteStyle = {
    ...defaultStyle,
    color: 'rgb(218,253,255)',
};

class Route extends React.Component {
    constructor(props) {
        super(props);

        const { style, isReduced, isRouteAlternative, positions } = props;
        this.updateGeometry(positions);
        this.updateStyle(style, isReduced, isRouteAlternative);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.positions !== this.props.positions) {
            this.updateGeometry(nextProps.positions);
        }
        if (nextProps.style !== this.props.style || nextProps.isReduced !== this.props.isReduced) {
            this.updateStyle(nextProps.style, nextProps.isReduced, nextProps.isRouteAlternative);
        }
    }

    updateGeometry(positions) {
        if (isEmpty(positions)) {
            throw new MapException(MISSING_POSITIONS_EXCEPTION_MSG);
        }

        /* keep reference to shared geometry to prevent recalculation if other values than points change */
        this.lineString = new H.geo.LineString();
        positions.forEach(singlePos => this.lineString.pushLatLngAlt(singlePos.lat, singlePos.lng));

        if (positions.length === 1) {
            const [position] = positions;
            this.lineString.pushLatLngAlt(position.lat, position.lng);
        }
    }

    updateStyle(style, isReduced, isRouteAlternative) {
        const mergedStyle = {
            ...(isRouteAlternative ? alternativeRouteStyle : defaultStyle),
            ...style,
        };

        const { color, width, borderColor, borderWidth, isBorderIncludedInWidth, lineDash } = mergedStyle;

        this.borderWidth = isReduced ? 0 : borderWidth;

        if (isBorderIncludedInWidth && this.borderWidth >= width) {
            throw new MapException(TO_LESS_LINE_WIDTH_EXCEPTION_MSG);
        }

        this.routeStyle = new H.map.SpatialStyle({
            strokeColor: isReduced ? LINE_STROKE_COLOR_REDUCED : color,
            lineWidth: isReduced ? borderWidth + width : width,
            lineCap: 'round',
            lineJoin: 'bevel',
            ...(lineDash && lineDash),
        });

        this.borderStyle = new H.map.SpatialStyle({
            strokeColor: isReduced ? BORDER_STROKE_COLOR_REDUCED : borderColor,
            lineWidth: borderWidth + width,
            lineCap: 'round',
            lineJoin: 'bevel',
            ...(lineDash && lineDash),
        });
    }

    render() {
        const {
            positions,
            startIcon,
            middleIcon,
            endIcon,
            arrowStyle,
            hasArrows,
            markers,
            eventListenerMap,
        } = this.props;
        return (
            <div>
                {this.borderWidth > 0 && (
                    <Polyline eventListenerMap={eventListenerMap} geometry={this.lineString} style={this.borderStyle} />
                )}
                <Polyline
                    hasArrows={hasArrows}
                    arrowStyle={arrowStyle}
                    eventListenerMap={eventListenerMap}
                    geometry={this.lineString}
                    style={this.routeStyle}
                />
                {startIcon && <Marker key={'startIcon'} position={positions[0]} icon={startIcon} />}
                {middleIcon && (
                    <Marker
                        key={'middleIcon'}
                        position={positions[Math.floor(positions.length / 2)]}
                        icon={middleIcon}
                    />
                )}
                {endIcon && <Marker key={'endIcon'} position={positions[positions.length - 1]} icon={endIcon} />}
                {isArray(markers) && markers.map(marker => marker)}
            </div>
        );
    }
}

Route.defaultProps = {
    hasArrows: true,
    arrowStyle: defaultArrowStyle,
    isReduced: false,
    isRouteAlternative: false,
    markers: [],
};

Route.propTypes = {
    arrowStyle: PropTypes.object,
    hasArrows: PropTypes.bool,
    positions: mapProps.positions.isRequired,
    style: PropTypes.shape({
        width: PropTypes.number,
        color: PropTypes.string,
        borderWidth: PropTypes.number,
        borderColor: PropTypes.string,
        isBorderIncludedInWidth: PropTypes.bool,
        lineDash: PropTypes.arrayOf(PropTypes.number),
    }),
    eventListenerMap: PropTypes.object,
    isReduced: PropTypes.bool,
    isRouteAlternative: PropTypes.bool,
    startIcon: mapProps.icon,
    middleIcon: mapProps.icon,
    endIcon: PropTypes.object,
    markers: PropTypes.array,
    api: mapProps.api,
};

export default Route;
