/* eslint-disable no-undef */
import map from 'lodash/fp/map';
import flatMap from 'lodash/fp/flatMap';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import first from 'lodash/fp/first';

const latLngFilterFunction = (lat, lng) => obj =>
    obj.getGeometry && obj.getGeometry().lat === lat && obj.getGeometry().lng === lng;

const typeFilterFunction = hereType => obj => obj instanceof hereType;

const markerFilterFunction = typeFilterFunction(H.map.AbstractMarker);

const groupFilterFunction = typeFilterFunction(H.map.Group);

const polylineFilterFunction = typeFilterFunction(H.map.Polyline);

const getMarkerIconClassName = domMarker => domMarker.getIcon().c.className;

const getPosition = marker => marker.getGeometry();

const getLatLngAltArray = polyline => polyline.getGeometry().getLatLngAltArray();

const getObjectType = hereObject => {
    if (hereObject instanceof H.map.Group) {
        return 'H.map.Group';
    }
    if (hereObject instanceof H.map.DomMarker) {
        return 'H.map.DomMarker';
    }
    if (hereObject instanceof H.map.Marker) {
        return 'H.map.Marker';
    }
    if (hereObject instanceof H.map.AbstractMarker) {
        return 'H.map.AbstractMarker';
    }
    if (hereObject instanceof H.map.Polyline) {
        return 'H.map.Polyline';
    }
    if (hereObject instanceof H.map.Rect) {
        return 'H.map.Rect';
    }
    if (hereObject instanceof H.map.Circle) {
        return 'H.map.Circle';
    }
    if (hereObject instanceof H.map.Polygon) {
        return 'H.map.Polygon';
    }
    return 'H.map.Object';
};
const getObjectTypes = map(getObjectType);

const getLayerType = hereLayer => {
    if (hereLayer instanceof H.map.layer.BaseTileLayer) {
        return 'H.map.layer.BaseTileLayer';
    }
    if (hereLayer instanceof H.map.layer.MarkerTileLayer) {
        return 'H.map.layer.MarkerTileLayer';
    }
    if (hereLayer instanceof H.map.layer.TileLayer) {
        return 'H.map.layer.TileLayer';
    }
    if (hereLayer instanceof H.map.layer.CanvasLayer) {
        return 'H.map.layer.CanvasLayer';
    }
    if (hereLayer instanceof H.map.layer.DomLayer) {
        return 'H.map.layer.DomLayer';
    }
    if (hereLayer instanceof H.map.layer.ObjectLayer) {
        return 'H.map.layer.ObjectLayer';
    }
    return 'H.map.layer.Layer';
};
const getLayerTypes = map(getLayerType);

export const createUtils = mapApi => {
    return {
        getCenter: () => {
            const center = mapApi.getCenter();
            return {
                lat: center.lat,
                lng: center.lng,
            };
        },
        getZoom: () => mapApi.getZoom(),
        getViewPort: () => {
            const viewPort = mapApi.getViewPort();
            return {
                width: viewPort.width,
                height: viewPort.height,
                margin: viewPort.margin,
                padding: {
                    top: viewPort.padding.top,
                    right: viewPort.padding.right,
                    bottom: viewPort.padding.bottom,
                    left: viewPort.padding.left,
                },
                center: {
                    x: viewPort.center.x,
                    y: viewPort.center.y,
                },
            };
        },
        getBounds: () => {
            const { bounds } = mapApi.getViewModel().getLookAtData();
            return {
                topLeft: {
                    lng: bounds.getTopLeft().lng,
                    lat: bounds.getTopLeft().lat,
                },
                bottomRight: {
                    lng: bounds.getBottomRight().lng,
                    lat: bounds.getBottomRight().lat,
                },
            };
        },
        getObjects: () => mapApi.getObjects(),
        getLayers: () => mapApi.getLayers().asArray(),
        getBaseLayer: () => mapApi.getBaseLayer(),
        getGroups: () => filter(groupFilterFunction)(mapApi.getObjects()),
        getMarkers: () => filter(markerFilterFunction)(mapApi.getObjects()),
        findMarkerByLatLng: (lat, lng) =>
            flow(
                filter(markerFilterFunction && latLngFilterFunction(lat, lng)),
                first
            )(mapApi.getObjects()),
        findMarkersByLatLng: (lat, lng) =>
            filter(markerFilterFunction && latLngFilterFunction(lat, lng))(mapApi.getObjects()),
        getRoutePolylines: () =>
            flow(
                filter(groupFilterFunction),
                flatMap(group => group.getObjects()),
                filter(polylineFilterFunction)
            )(mapApi.getObjects()),
        getObjectType,
        getObjectTypes,
        getLayerType,
        getLayerTypes,
        getMarkerIconClassName,
        getPosition,
        getLatLngAltArray,
    };
};
