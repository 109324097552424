/* eslint-disable no-undef */
import { useContext, useRef, useLayoutEffect } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { MapContext } from '../context';

const HERE_ITEM_CLASS = 'H_context_menu_item';

const centeredSpinner =
    '<div class="flex-1-1 display-flex justify-content-center align-items-center"><div class="spinner"></div></div>';

const getDOMMenuItems = () => document.getElementsByClassName(HERE_ITEM_CLASS);

// Query DOM nodes for "H_context_menu_item" and update label when children label has changed.
// This function allows to use HTML markup for the menu items in the second render
const updateMenuItemLabels = (nodes = [], items = []) => {
    setTimeout(() => {
        Array.from(nodes).forEach((itemNode, index) => {
            const item = items[index];
            if (!item) {
                return;
            }

            const { hasSpinner, className, labelClassName = '', icon, label } = item.props;

            const contentLabel = `<span class="${labelClassName}">${label}</span>`;
            const labelWithIcon = `<span class="rioglyph ${icon} margin-right-5"></span>${contentLabel}`;
            const content = icon ? labelWithIcon : contentLabel;

            if (hasSpinner) {
                itemNode.innerHTML = centeredSpinner;
            } else {
                itemNode.innerHTML = content;
            }

            const wrapperClassList = itemNode.classList;

            // Since classList.add() does not allow for space separated multiple classes, we have to add them separately
            if (className) {
                className.split(' ').map(singleClassName => wrapperClassList.add(singleClassName));
            }
        });
    }, 0);
};

const addItemsToEvent = (items, event, map, targetPosition) => {
    const amount = items.length - 1;

    // Get geo coordinates from the screen coordinates or use a fixed set to be returned when
    // clicking the menu item.
    const coord = targetPosition || map.screenToGeo(event.viewportX, event.viewportY);

    items.map((item, index) => {
        if (!item) {
            return;
        }

        const { label, disabled, callback } = item.props;

        event.items.push(
            new H.util.ContextItem({
                label,
                disabled,
                callback: () => callback(coord, map),
            })
        );

        // add separator but for last element
        if (index < amount) {
            event.items.push(H.util.ContextItem.SEPARATOR);
        }
    });
};

const ContextMenu = props => {
    const { api, onOpen, menuItems: items, contextMenuEvent, targetPosition } = props;

    const mapContext = useContext(MapContext);
    const { map: hereMap } = api || mapContext;

    // Use a ref to store the target position used when a map object was clicked with a fixed
    // position to return that position in the onOpen callback instead of the dynamic map position
    const targetPositionRef = useRef();
    targetPositionRef.current = targetPosition;

    useLayoutEffect(() => {
        // Add the context menu item to the target event and not to the map event itself
        // as every element on the map need to have context menu items added to the event they
        // are triggered from
        if (contextMenuEvent && contextMenuEvent !== hereMap && isEmpty(contextMenuEvent.items)) {
            addItemsToEvent(items, contextMenuEvent, hereMap, targetPositionRef.current);
        }

        updateMenuItemLabels(getDOMMenuItems(), items);
    }, [items, contextMenuEvent, targetPositionRef.current]);

    const handleMapContextMenu = event => {
        if (event.type !== 'contextmenu') {
            return;
        }

        // "contextmenu" event might be triggered not only by a pointer,
        // but a keyboard button as well. That's why ContextMenuEvent
        // doesn't have a "currentPointer" property.
        // Instead it has "viewportX" and "viewportY" properties
        // for the associates position.

        // Reset previous target positions when the map was clicked
        if (event.target === hereMap) {
            targetPositionRef.current = undefined;
        }

        // Get geo coordinates from the screen coordinates.
        const coord = targetPositionRef.current || hereMap.screenToGeo(event.viewportX, event.viewportY);
        onOpen(coord);

        // As we already handle contextmenu event callback on map object separately,
        // we don't do anything if target is different than the map.
        if (event.target !== hereMap) {
            return;
        }

        if (!items) {
            return;
        }

        if (isEmpty(event.items)) {
            addItemsToEvent(items, event, hereMap, targetPosition);
            updateMenuItemLabels(getDOMMenuItems(), items);
        }
    };

    // The contextmenu event handler has to be registered in any case to handle context menu events and to
    // trigger the callback when the menu opens.
    useLayoutEffect(() => {
        if (!hereMap) {
            return;
        }

        hereMap.addEventListener('contextmenu', handleMapContextMenu);

        return () => {
            hereMap.removeEventListener('contextmenu', handleMapContextMenu);
        };
    }, []);

    return null;
};

export default ContextMenu;
