/* eslint-disable no-undef */
import React from 'react';
import ReactDomServer from 'react-dom/server';
import PropTypes from 'prop-types';

import mapProps from '../../../../utils/proptypes';
import {
    addEventListenerMap,
    removeEventListenerMap,
    checkAndUpdateEventListenerMap,
} from '../../../../utils/eventHandling';
import MapException from '../../../../exceptions/MapException';
import { MapContext } from '../../../context';

export const isHMapIcon = icon => icon instanceof H.map.Icon;

export const isDomMakerNeeded = icon => (!icon ? false : !isHMapIcon(icon));

export function getOrCreateIcon(icon) {
    if (!icon || isHMapIcon(icon) || icon instanceof H.map.DomIcon) {
        return;
    }
    const renderedIcon = React.isValidElement(icon) ? ReactDomServer.renderToStaticMarkup(icon) : icon;
    return new H.map.DomIcon(renderedIcon);
}

class Marker extends React.Component {
    constructor(props, context) {
        super(props, context);
        //console.log(context);
        this.setupMarker(props);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { position, customData, icon } = this.props;

        checkAndUpdateEventListenerMap(this.marker, this.props, nextProps);

        if (position !== nextProps.position) {
            this.marker.setGeometry(this.getConvertedPosition(nextProps));
        }

        this.marker.draggable = !!nextProps.draggable;

        if (customData !== nextProps.customData) {
            this.marker.setData(nextProps.customData);
        }

        if (icon !== nextProps.icon) {
            if (isDomMakerNeeded(icon) !== isDomMakerNeeded(nextProps.icon)) {
                // icon type changed so we need to change the marker type
                this.destroyMarker();
                this.setupMarker(nextProps);
            } else {
                this.marker.setIcon(getOrCreateIcon(nextProps.icon));
            }
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    componentWillUnmount() {
        this.destroyMarker();
    }

    render() {
        return null;
    }

    setupMarker(nextProps) {
        const { customData, draggable } = this.props;

        const MarkerConstructor = isDomMakerNeeded(nextProps.icon) ? H.map.DomMarker : H.map.Marker;

        this.marker = new MarkerConstructor(this.getConvertedPosition(nextProps), {
            icon: getOrCreateIcon(nextProps.icon),
        });
        this.marker.setData(customData);
        this.marker.draggable = !!draggable;
        this.context.group.addObject(this.marker);

        addEventListenerMap(this.marker, nextProps.eventListenerMap, this.context.map);
    }

    destroyMarker() {
        removeEventListenerMap(this.marker);
        if (this.context.group.getObjects().includes(this.marker)) {
            this.context.group.removeObject(this.marker);
        }
        this.marker.dispose();
    }

    getConvertedPosition(props) {
        return this.convertPosition(this.getPosition(props));
    }

    getPosition(props) {
        const { position } = props;
        if (!position) {
            throw new MapException('Error in Marker: invalid position property');
        }

        if (Array.isArray(position)) {
            position.forEach(pos => this.checkPositionProperties(pos));
        } else {
            this.checkPositionProperties(position);
        }

        return position;
    }

    checkPositionProperties(position) {
        ['lat', 'lng'].forEach(propertyName => {
            // eslint-disable-next-line no-prototype-builtins
            if (!position.hasOwnProperty(propertyName)) {
                throw new MapException(`Error in Marker: position object requires property "${propertyName}"`);
            }
        });
    }

    convertPosition(pos) {
        return Array.isArray(pos) ? new H.geo.MultiPoint(pos) : pos;
    }
}

Marker.contextType = MapContext;

Marker.propTypes = {
    position: PropTypes.oneOfType([mapProps.position, mapProps.positions]).isRequired,
    icon: mapProps.icon,
    eventListenerMap: PropTypes.object,
    customData: PropTypes.object,
    draggable: PropTypes.bool,
};

export default Marker;
