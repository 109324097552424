/* eslint-disable no-undef */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { MapContext } from '../../context';

const MapLayerGroup = ({ children, minZoom, maxZoom, isVisible }) => {
    const context = useContext(MapContext);
    const [group, setGroup] = useState();
    const [childApi, setChildApi] = useState();

    useEffect(() => {
        const newGroup = new H.map.Group({
            min: parseFloat(minZoom),
            max: parseFloat(maxZoom),
        });

        newGroup.setVisibility(isVisible);
        context.group.addObject(newGroup);
        setGroup(newGroup);

        const newChildApi = {
            ...context,
            group: newGroup,
        };
        setChildApi(newChildApi);

        return () => {
            if (context.group.getObjects().includes(newGroup)) {
                context.group.removeObject(newGroup);
            }
            newGroup.dispose();
        };
    }, []);

    useEffect(() => {
        if (group) {
            group.setVisibility(isVisible);
        }
    }, [isVisible]);

    return (
        <div className={'MapLayerGroup'}>
            {childApi ? <MapContext.Provider value={childApi}>{children}</MapContext.Provider> : null}
        </div>
    );
};

MapLayerGroup.propTypes = {
    isVisible: PropTypes.bool,
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
};

MapLayerGroup.defaultProps = {
    isVisible: true,
    minZoom: -Infinity,
    maxZoom: Infinity,
};

export default MapLayerGroup;
