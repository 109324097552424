import React from 'react';
import PropTypes from 'prop-types';

import { MapIcon, ZOOM_IN, ZOOM_OUT } from '../../../icons/MapIcon';
import classNames from 'classnames';

const ZoomButtons = ({ onZoomIn, onZoomOut }) => {
    const buttonClasses = classNames('ZoomButtons');

    return (
        <div className={buttonClasses}>
            <div className='MapSettingsTile' onClick={onZoomIn}>
                <MapIcon name={ZOOM_IN} className='display-block hover-scale-110' height={16} width={16} />
            </div>
            <div className='MapSettingsTileSpacer' />
            <div className='MapSettingsTile' onClick={onZoomOut}>
                <MapIcon name={ZOOM_OUT} className='display-block hover-scale-110' height={16} width={16} />
            </div>
        </div>
    );
};

ZoomButtons.propTypes = {
    onZoomIn: PropTypes.func,
    onZoomOut: PropTypes.func,
};

export default ZoomButtons;
