/* eslint-disable no-undef */
import PropTypes from 'prop-types';

const ContextMenuItem = props => {
    // Note: not all defined props are passed to the Here ContextItem are used in ContextMenu component
    const { label, disabled, callback } = props;

    return new H.util.ContextItem({
        label,
        disabled,
        callback,
    });
};

ContextMenuItem.defaultProps = {
    label: '',
    disabled: false,
    callback: () => {},
    hasSpinner: false,
    className: '',
    labelClassName: '',
};

ContextMenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    callback: PropTypes.func,
    hasSpinner: PropTypes.bool,
    icon: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
};

export default ContextMenuItem;
