/* eslint-disable no-undef */
import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/fp/isNil';

import mapProps from '../../../utils/proptypes';
import { MapContext } from '../../context';
import { useUpdateProp, useUpdateEventListenerMap } from '../../../utils/hooks';

function createGeometry(points) {
    const lineString = new H.geo.LineString();

    points.forEach(p => {
        if (isNil(p.lat) || isNil(p.lng) || isNaN(p.lat) || isNaN(p.lng)) {
            return;
        }
        lineString.pushLatLngAlt(p.lat, p.lng);
    });

    return new H.geo.Polygon(lineString);
}

const Polygon = ({ points, style = H.map.SpatialStyle.DEFAULT_STYLE, eventListenerMap }) => {
    const context = useContext(MapContext);
    const [polygon, setPolygon] = useState();

    useEffect(() => {
        const newPolygon = new H.map.Polygon(createGeometry(points), { style });
        context.group.addObject(newPolygon);
        setPolygon(newPolygon);
        return () => {
            if (context.group.getObjects().includes(newPolygon)) {
                context.group.removeObject(newPolygon);
            }
            newPolygon.dispose();
        };
    }, []);

    useEffect(() => {
        if (polygon) {
            polygon.setGeometry(createGeometry(points));
        }
    }, [points]);

    useUpdateProp(polygon, 'setStyle', style);
    useUpdateEventListenerMap(polygon, eventListenerMap);

    return null;
};

Polygon.propTypes = {
    points: mapProps.positions.isRequired,
    style: PropTypes.object,
    eventListenerMap: PropTypes.object,
};

export default Polygon;
