import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MapSettingsPanel = props => {
    const { children, className } = props;

    const panelClassNames = classNames('MapSettingsPanel', className);

    return <div className={panelClassNames}>{children}</div>;
};

MapSettingsPanel.defaultProps = {
    className: '',
};

MapSettingsPanel.propTypes = {
    className: PropTypes.string,
};

export default MapSettingsPanel;
