/* eslint-disable no-undef */
import { getOrCreateIcon, isDomMakerNeeded } from '../components/features/basics/marker/Marker';

const getBoundingBoxForCluster = cluster => {
    let minLat = null;
    let minLng = null;
    let maxLat = null;
    let maxLng = null;

    cluster.forEachDataPoint(dataPoint => {
        const position = dataPoint.getPosition();
        if (minLat === null || minLat > position.lat) {
            minLat = position.lat;
        }
        if (minLng === null || minLng > position.lng) {
            minLng = position.lng;
        }
        if (maxLat === null || maxLat < position.lat) {
            maxLat = position.lat;
        }
        if (maxLng === null || maxLng < position.lng) {
            maxLng = position.lng;
        }
    });
    return new H.geo.Rect(maxLat, minLng, minLat, maxLng);
};

export const ClusterUtils = {
    getBoundingBoxForCluster,
};

const wrapClusterPresentation = func => {
    return cluster => {
        const datas = [];
        cluster.forEachDataPoint(point => datas.push(point.getData()));
        const icon = getOrCreateIcon(func(datas, cluster.getPosition()));
        const MarkerConstructor = isDomMakerNeeded(icon) ? H.map.DomMarker : H.map.Marker;
        const marker = new MarkerConstructor(cluster.getPosition(), {
            icon: icon,
            min: cluster.getMinZoom(),
            max: cluster.getMaxZoom(),
        });
        marker.setData(cluster);
        return marker;
    };
};

const wrapNoisePresentation = func => {
    return point => {
        const icon = getOrCreateIcon(func(point.getData(), point.getPosition()));
        const MarkerConstructor = isDomMakerNeeded(icon) ? H.map.DomMarker : H.map.Marker;
        const marker = new MarkerConstructor(point.getPosition(), {
            icon: icon,
            min: point.getMinZoom(),
        });
        marker.setData(point);
        return marker;
    };
};

export const wrapSimpleTheme = theme => ({
    getClusterPresentation: wrapClusterPresentation(theme.getClusterPresentation),
    getNoisePresentation: wrapNoisePresentation(theme.getNoisePresentation),
});
