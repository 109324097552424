/* eslint-disable no-undef */
import React from 'react';

import Layer from './Layer';

function getIncidentsLayer(api) {
    return api.defaultLayers.vector.normal.trafficincidents;
}

const IncidentsLayer = props => <Layer {...props} createLayer={getIncidentsLayer} />;

export default IncidentsLayer;
