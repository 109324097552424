/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import mapProps from '../../../../utils/proptypes';

import {
    addEventListenerMap,
    removeEventListenerMap,
    checkAndUpdateEventListenerMap,
} from '../../../../utils/eventHandling';

class DomMarker extends React.Component {
    constructor(props, context) {
        super(props, context);

        const icon = new H.map.DomIcon(props.element);
        this.marker = new H.map.DomMarker(this.convertPosition(props.position), { icon });

        context.api.group.addObject(this.marker);

        addEventListenerMap(this.marker, props.eventListenerMap, this.context.api.map);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        checkAndUpdateEventListenerMap(this.marker, this.props, nextProps);
        if (this.props.position !== nextProps.position) {
            this.marker.setGeometry(this.convertPosition(nextProps.position));
        }
        if (this.props.element !== nextProps.element) {
            const icon = new H.map.DomIcon(nextProps.element);
            this.marker.setIcon(icon);
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    componentWillUnmount() {
        removeEventListenerMap(this.marker);
        if (this.context.api.group.includes(this.marker)) {
            this.context.api.group.removeObject(this.marker);
        }
        this.marker.dispose();
    }

    render() {
        return null;
    }

    convertPosition(pos) {
        return Array.isArray(pos) ? new H.geo.MultiPoint(pos) : pos;
    }
}

DomMarker.contextTypes = {
    api: mapProps.api,
};

DomMarker.propTypes = {
    element: PropTypes.object.isRequired,
    eventListenerMap: PropTypes.object,
    position: PropTypes.oneOfType([mapProps.position, mapProps.positions]).isRequired,
};

export default DomMarker;
