import React from 'react';

import { FORMAT, TILE_SIZE } from '../../../constants';
import BaseLayer from './BaseLayer';

// old Fleetadmin map type = 'normal.day.grey'

const createDefaultLayer = (api, language, showTraffic) => {
    const type = showTraffic ? 'traffic' : 'base';
    const tileType = showTraffic ? 'traffictile' : 'maptile';
    return api.platform
        .getMapTileService({ type })
        .createTileLayer(tileType, 'normal.day', TILE_SIZE, FORMAT, {
            style: 'alps',
            lg: language,
        });
};

const DefaultLayer = props => {
    return <BaseLayer {...props} createBaseLayer={createDefaultLayer} />;
};

export default DefaultLayer;
