/* eslint-disable no-undef */
import React from 'react';

import { FORMAT, TILE_SIZE } from '../../constants';
import Layer from './Layer';

const createTrafficLayer = (api, language) => {
    //return api.defaultLayers.vector.normal.traffic;
    return api.platform
        .getMapTileService({ type: 'traffic' })
        .createTileLayer('flowtile', 'normal.day.grey', TILE_SIZE, FORMAT, {
            style: 'alps',
            lg: language,
        });
};

const TrafficLayer = props => <Layer {...props} createLayer={createTrafficLayer} />;

export default TrafficLayer;
