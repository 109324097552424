// As "cssuseragent" is accessing the html dom object directly without checking whether it exists or not, we need to
// use old shool require() to have a conditional import only when html really exists. This avoids issues when
// running the UIKIT in a web-worker
if (document && document.documentElement) {
    // eslint-disable-next-line global-require
    require('cssuseragent');
}

const isDefined = val => val !== undefined && val !== null;

// see https://patrickhlauke.github.io/touch/touchscreen-detection/ for reference
export const hasTouch = () => {
    if (window.PointerEvent && isDefined(navigator.maxTouchPoints)) {
        // if Pointer Events are supported, just check maxTouchPoints
        if (navigator.maxTouchPoints > 0) {
            return true;
        }
    } else if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
        // no Pointer Events...
        // check for any-pointer:coarse which mostly means touchscreen
        return true;
    } else if (window.TouchEvent || isDefined(window.ontouchstart)) {
        // last resort - check for exposed touch events API / event handler
        return true;
    }

    return false;
};

export const isMobile = () => document && document.documentElement.classList.contains('ua-mobile');
export const isDesktop = () => document && document.documentElement.classList.contains('ua-desktop');

export const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (_) {
        return true;
    }
};

// Disables the Zoom for the mobile devices.
// This is not good for accessibility but makes the webpage feel more like a native app.
// <meta name="viewport" content="user-scalable=0 ..." />
const USER_SCALABLE = 'user-scalable=0 maximum-scale=1';
export const toggleZoomOnMobile = () => {
    if (!document) {
        return;
    }

    const viewportMeta = document.head.querySelector('[name="viewport"]');
    if (!viewportMeta) {
        return;
    }

    const isScalable = viewportMeta.content.includes(USER_SCALABLE);

    // Restrict the zoom for mobile if not yet set
    if (isMobile() && !isScalable) {
        viewportMeta.content += ` ${USER_SCALABLE}`;
        return;
    }

    // Remove the setting, for non mobile cases
    if (!isMobile() && isScalable) {
        viewportMeta.content = viewportMeta.content.replace(USER_SCALABLE, '').trim();
        return;
    }
};
