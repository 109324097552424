import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { MAP_TYPE_DEFAULT, MAP_TYPE_FLEET_STYLE, MAP_TYPE_SATELLITE, MAP_TYPE_TERRAIN } from '../../../constants';
import MapSettingsPanel from '../MapSettingsPanel';
import MapSettingsItem from '../MapSettingsItem';
import MapSettingsTile from '../MapSettingsTile';

import {
    MapIcon,
    MAP_TYPE_FLAT_ACTIVE,
    MAP_TYPE_FLAT_INACTIVE,
    MAP_TYPE_FLAT_TRUCK_ACTIVE,
    MAP_TYPE_FLAT_TRUCK_INACTIVE,
    MAP_TYPE_SATELITE_ACTIVE,
    MAP_TYPE_SATELITE_INACTIVE,
    MAP_TYPE_TERRAIN_ACTIVE,
    MAP_TYPE_TERRAIN_INACTIVE,
} from '../../../../icons/MapIcon';

const MapTypeSettings = props => {
    const { mapType, onMapTypeChange, ...remainingProps } = props;

    const type = useMemo(
        () => ({
            isDefault: mapType === MAP_TYPE_DEFAULT,
            isFleetStyle: mapType === MAP_TYPE_FLEET_STYLE,
            isTerrain: mapType === MAP_TYPE_TERRAIN,
            isSatellite: mapType === MAP_TYPE_SATELLITE,
        }),
        [mapType]
    );

    const getIcon = currentType => {
        if (currentType.isDefault) {
            return <MapIcon name={MAP_TYPE_FLAT_INACTIVE} />;
        }
        if (currentType.isFleetStyle) {
            return <MapIcon name={MAP_TYPE_FLAT_TRUCK_INACTIVE} />;
        }
        if (currentType.isTerrain) {
            return <MapIcon name={MAP_TYPE_TERRAIN_INACTIVE} />;
        }
        if (currentType.isSatellite) {
            return <MapIcon name={MAP_TYPE_SATELITE_INACTIVE} />;
        }
    };

    return (
        <MapSettingsTile
            className='MapTypeSettings'
            inactiveIcon={getIcon(type)}
            panel={
                <MapSettingsPanel>
                    <MapSettingsItem
                        className='MapTypeSatelite'
                        onClick={() => onMapTypeChange(MAP_TYPE_SATELLITE)}
                        isActive={type.isSatellite}
                        activeIcon={<MapIcon name={MAP_TYPE_SATELITE_ACTIVE} />}
                        inactiveIcon={<MapIcon name={MAP_TYPE_SATELITE_INACTIVE} />}
                    />
                    <MapSettingsItem
                        className='MapTypeTerrain'
                        onClick={() => onMapTypeChange(MAP_TYPE_TERRAIN)}
                        isActive={type.isTerrain}
                        activeIcon={<MapIcon name={MAP_TYPE_TERRAIN_ACTIVE} />}
                        inactiveIcon={<MapIcon name={MAP_TYPE_TERRAIN_INACTIVE} />}
                    />
                    <MapSettingsItem
                        className='MapTypeFlatTruck'
                        onClick={() => onMapTypeChange(MAP_TYPE_FLEET_STYLE)}
                        isActive={type.isFleetStyle}
                        activeIcon={<MapIcon name={MAP_TYPE_FLAT_TRUCK_ACTIVE} />}
                        inactiveIcon={<MapIcon name={MAP_TYPE_FLAT_TRUCK_INACTIVE} />}
                    />
                    <MapSettingsItem
                        className='MapTypeFlat'
                        onClick={() => onMapTypeChange(MAP_TYPE_DEFAULT)}
                        isActive={type.isDefault}
                        activeIcon={<MapIcon name={MAP_TYPE_FLAT_ACTIVE} />}
                        inactiveIcon={<MapIcon name={MAP_TYPE_FLAT_INACTIVE} />}
                    />
                </MapSettingsPanel>
            }
            {...remainingProps}
        />
    );
};

MapTypeSettings.displayName = 'MapTypeSettings';

MapTypeSettings.defaultProps = {
    onMapTypeChange: () => {},
};

MapTypeSettings.propTypes = {
    mapType: PropTypes.string,
    onMapTypeChange: PropTypes.func,
};

export default MapTypeSettings;
