const twoToThreeMapping = {
    ab: 'abk',
    aa: 'aar',
    af: 'afr',
    ak: 'aka',
    sq: 'alb',
    am: 'amh',
    ar: 'ara',
    an: 'arg',
    hy: 'arm',
    as: 'asm',
    av: 'ava',
    ae: 'ave',
    ay: 'aym',
    az: 'aze',
    bm: 'bam',
    ba: 'bak',
    eu: 'baq',
    be: 'bel',
    bn: 'ben',
    bh: 'bih',
    bi: 'bis',
    bs: 'bos',
    br: 'bre',
    bg: 'bul',
    my: 'bur',
    ca: 'cat',
    ch: 'cha',
    ce: 'che',
    ny: 'nya',
    zh: 'chi',
    cv: 'chv',
    kw: 'cor',
    co: 'cos',
    cr: 'cre',
    hr: 'hrv',
    cs: 'cze',
    da: 'dan',
    dv: 'div',
    nl: 'dut',
    dz: 'dzo',
    en: 'eng',
    eo: 'epo',
    et: 'est',
    ee: 'ewe',
    fo: 'fao',
    fj: 'fij',
    fi: 'fin',
    fr: 'fre',
    ff: 'ful',
    gl: 'glg',
    ka: 'geo',
    de: 'ger',
    el: 'gre',
    gn: 'grn',
    gu: 'guj',
    ht: 'hat',
    ha: 'hau',
    he: 'heb',
    hz: 'her',
    hi: 'hin',
    ho: 'hmo',
    hu: 'hun',
    ia: 'ina',
    id: 'ind',
    ie: 'ile',
    ga: 'gle',
    ig: 'ibo',
    ik: 'ipk',
    io: 'ido',
    is: 'ice',
    it: 'ita',
    iu: 'iku',
    ja: 'jpn',
    jv: 'jav',
    kl: 'kal',
    kn: 'kan',
    kr: 'kau',
    ks: 'kas',
    kk: 'kaz',
    km: 'khm',
    ki: 'kik',
    rw: 'kin',
    ky: 'kir',
    kv: 'kom',
    kg: 'kon',
    ko: 'kor',
    ku: 'kur',
    kj: 'kua',
    la: 'lat',
    lb: 'ltz',
    lg: 'lug',
    li: 'lim',
    ln: 'lin',
    lo: 'lao',
    lt: 'lit',
    lu: 'lub',
    lv: 'lav',
    gv: 'glv',
    mk: 'mac',
    mg: 'mlg',
    ms: 'may',
    ml: 'mal',
    mt: 'mlt',
    mi: 'mao',
    mr: 'mar',
    mh: 'mah',
    mn: 'mon',
    na: 'nau',
    nv: 'nav',
    nd: 'nde',
    ne: 'nep',
    ng: 'ndo',
    nb: 'nob',
    nn: 'nno',
    no: 'nor',
    ii: 'iii',
    nr: 'nbl',
    oc: 'oci',
    oj: 'oji',
    cu: 'chu',
    om: 'orm',
    or: 'ori',
    os: 'oss',
    pa: 'pan',
    pi: 'pli',
    fa: 'per',
    pox: 'sla',
    pl: 'pol',
    ps: 'pus',
    pt: 'por',
    qu: 'que',
    rm: 'roh',
    rn: 'run',
    ro: 'rum',
    ru: 'rus',
    sa: 'san',
    sc: 'srd',
    sd: 'snd',
    se: 'sme',
    sm: 'smo',
    sg: 'sag',
    sr: 'srp',
    gd: 'gla',
    sn: 'sna',
    si: 'sin',
    sk: 'slo',
    sl: 'slv',
    so: 'som',
    st: 'sot',
    es: 'spa',
    su: 'sun',
    sw: 'swa',
    ss: 'ssw',
    sv: 'swe',
    ta: 'tam',
    te: 'tel',
    tg: 'tgk',
    th: 'tha',
    ti: 'tir',
    bo: 'tib',
    tk: 'tuk',
    tl: 'tgl',
    tn: 'tsn',
    to: 'ton',
    tr: 'tur',
    ts: 'tso',
    tt: 'tat',
    tw: 'twi',
    ty: 'tah',
    ug: 'uig',
    uk: 'ukr',
    ur: 'urd',
    uz: 'uzb',
    ve: 'ven',
    vi: 'vie',
    vo: 'vol',
    wa: 'wln',
    cy: 'wel',
    wo: 'wol',
    fy: 'fry',
    xh: 'xho',
    yi: 'yid',
    yo: 'yor',
    za: 'zha',
    zu: 'zul',
};
const threeToTwoMapping = {
    abk: 'ab',
    aar: 'aa',
    afr: 'af',
    aka: 'ak',
    alb: 'sq',
    amh: 'am',
    ara: 'ar',
    arg: 'an',
    arm: 'hy',
    asm: 'as',
    ava: 'av',
    ave: 'ae',
    aym: 'ay',
    aze: 'az',
    bam: 'bm',
    bak: 'ba',
    baq: 'eu',
    bel: 'be',
    ben: 'bn',
    bih: 'bh',
    bis: 'bi',
    bos: 'bs',
    bre: 'br',
    bul: 'bg',
    bur: 'my',
    cat: 'ca',
    cha: 'ch',
    che: 'ce',
    nya: 'ny',
    chi: 'zh',
    chv: 'cv',
    cor: 'kw',
    cos: 'co',
    cre: 'cr',
    hrv: 'hr',
    cze: 'cs',
    dan: 'da',
    div: 'dv',
    dut: 'nl',
    dzo: 'dz',
    eng: 'en',
    epo: 'eo',
    est: 'et',
    ewe: 'ee',
    fao: 'fo',
    fij: 'fj',
    fin: 'fi',
    fre: 'fr',
    ful: 'ff',
    glg: 'gl',
    geo: 'ka',
    ger: 'de',
    gre: 'el',
    grn: 'gn',
    guj: 'gu',
    hat: 'ht',
    hau: 'ha',
    heb: 'he',
    her: 'hz',
    hin: 'hi',
    hmo: 'ho',
    hun: 'hu',
    ina: 'ia',
    ind: 'id',
    ile: 'ie',
    gle: 'ga',
    ibo: 'ig',
    ipk: 'ik',
    ido: 'io',
    ice: 'is',
    ita: 'it',
    iku: 'iu',
    jpn: 'ja',
    jav: 'jv',
    kal: 'kl',
    kan: 'kn',
    kau: 'kr',
    kas: 'ks',
    kaz: 'kk',
    khm: 'km',
    kik: 'ki',
    kin: 'rw',
    kir: 'ky',
    kom: 'kv',
    kon: 'kg',
    kor: 'ko',
    kur: 'ku',
    kua: 'kj',
    lat: 'la',
    ltz: 'lb',
    lug: 'lg',
    lim: 'li',
    lin: 'ln',
    lao: 'lo',
    lit: 'lt',
    lub: 'lu',
    lav: 'lv',
    glv: 'gv',
    mac: 'mk',
    mlg: 'mg',
    may: 'ms',
    mal: 'ml',
    mlt: 'mt',
    mao: 'mi',
    mar: 'mr',
    mah: 'mh',
    mon: 'mn',
    nau: 'na',
    nav: 'nv',
    nde: 'nd',
    nep: 'ne',
    ndo: 'ng',
    nob: 'nb',
    nno: 'nn',
    nor: 'no',
    iii: 'ii',
    nbl: 'nr',
    oci: 'oc',
    oji: 'oj',
    chu: 'cu',
    orm: 'om',
    ori: 'or',
    oss: 'os',
    pan: 'pa',
    pli: 'pi',
    per: 'fa',
    sla: 'pox',
    pol: 'pl',
    pus: 'ps',
    por: 'pt',
    que: 'qu',
    roh: 'rm',
    run: 'rn',
    rum: 'ro',
    rus: 'ru',
    san: 'sa',
    srd: 'sc',
    snd: 'sd',
    sme: 'se',
    smo: 'sm',
    sag: 'sg',
    srp: 'sr',
    gla: 'gd',
    sna: 'sn',
    sin: 'si',
    slo: 'sk',
    slv: 'sl',
    som: 'so',
    sot: 'st',
    spa: 'es',
    sun: 'su',
    swa: 'sw',
    ssw: 'ss',
    swe: 'sv',
    tam: 'ta',
    tel: 'te',
    tgk: 'tg',
    tha: 'th',
    tir: 'ti',
    tib: 'bo',
    tuk: 'tk',
    tgl: 'tl',
    tsn: 'tn',
    ton: 'to',
    tur: 'tr',
    tso: 'ts',
    tat: 'tt',
    twi: 'tw',
    tah: 'ty',
    uig: 'ug',
    ukr: 'uk',
    urd: 'ur',
    uzb: 'uz',
    ven: 've',
    vie: 'vi',
    vol: 'vo',
    wln: 'wa',
    wel: 'cy',
    wol: 'wo',
    fry: 'fy',
    xho: 'xh',
    yid: 'yi',
    yor: 'yo',
    zha: 'za',
    zul: 'zu',
};

function parseLocale(locale = 'eng') {
    const parts = locale.split('-');
    if (parts.length === 2) {
        return parts[0];
    }
    return locale;
}

export function mapToLongLocale(locale) {
    const key = parseLocale(locale);
    if (threeToTwoMapping[key]) {
        return key;
    } else if (twoToThreeMapping[key]) {
        return twoToThreeMapping[key];
    }
    return 'eng';
}

export function mapToShortLocale(locale) {
    const key = parseLocale(locale);
    if (twoToThreeMapping[key]) {
        return key;
    } else if (threeToTwoMapping[key]) {
        return threeToTwoMapping[key];
    }
    return 'en';
}
