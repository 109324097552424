import React from 'react';

import { FORMAT, TILE_SIZE } from '../../../constants';
import BaseLayer from './BaseLayer';

const createTerrainLayer = (api, language, showTraffic) => {
    const type = showTraffic ? 'traffic' : 'aerial';
    const tileType = showTraffic ? 'traffictile' : 'maptile';
    return api.platform
        .getMapTileService({ type })
        .createTileLayer(tileType, 'terrain.day', TILE_SIZE, FORMAT, {
            style: 'default',
            lg: language,
        });
};

const TerrainLayer = props => {
    return <BaseLayer {...props} createBaseLayer={createTerrainLayer} />;
};

export default TerrainLayer;
