import React from 'react';
import mapProps from '../../../../utils/proptypes';
import ClusterLayer from './ClusterLayer';
import { wrapSimpleTheme } from '../../../../utils/clustering';

const SimpleClusterLayer = props => {
    const { simpleTheme, ...rest } = props;
    const newProps = {
        clusterTheme: simpleTheme && wrapSimpleTheme(simpleTheme),
        ...rest,
    };
    return <ClusterLayer {...newProps} />;
};

SimpleClusterLayer.propTypes = {
    simpleTheme: mapProps.clusterTheme,
};

export default SimpleClusterLayer;
