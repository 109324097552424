/* eslint-disable no-undef, camelcase */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/fp/isFunction';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';
import throttle from 'lodash/fp/throttle';

import mapProps from '../utils/proptypes';
import addEventListener from '../../../utils/addEventListener';
import '../herePolyfill';

import {
    MAP_TYPE_DEFAULT,
    MAP_TYPE_FLEET_STYLE,
    MAP_TYPE_SATELLITE,
    MAP_TYPE_TERRAIN,
    MAP_LAYER_INCIDENTS,
    MAP_LAYER_TRAFFIC,
    MAP_LAYER_ROAD_RESTRICTIONS,
    TILE_SIZE,
    HIGH_RES_PPI,
} from './constants';
// import MapSettings from './features/old/MapSettings';
import ZoomButtons from './features/settings/ZoomButtons';
import IncidentsLayer from './features/layers/IncidentsLayer';
import TrafficLayer from './features/layers/TrafficLayer';
import TruckLayer from './features/layers/TruckLayer';
import DefaultLayer from './features/layers/baselayers/DefaultLayer';
import FleetStyleLayer from './features/layers/baselayers/FleetStyleLayer';
import SatelliteLayer from './features/layers/baselayers/SatelliteLayer';
import TerrainLayer from './features/layers/baselayers/TerrainLayer';
import { addEventListenerMap, removeEventListenerMap } from '../utils/eventHandling';
import { MapContext, MapSettingsContext } from './context';
import { createUtils } from './mapUtils';

export const NO_CREDENTIALS_ERROR_MESSAGE = 'Cannot instantiate PlainMap. Missing credentials property.';

const RESIZE_THROTTLE = 1100; // 100 more than the ApplicationLayout handler

const getBounds = boundingBox => {
    const { top, left, bottom, right } = boundingBox;
    return new H.geo.Rect(top, left, bottom, right);
};

const getLayer = (api, layer, language) => {
    switch (layer) {
        case MAP_LAYER_TRAFFIC:
            return <TrafficLayer api={api} language={language} key={layer} layerIndex={1} />;
        case MAP_LAYER_INCIDENTS:
            return <IncidentsLayer api={api} language={language} key={layer} layerIndex={2} />;
        case MAP_LAYER_ROAD_RESTRICTIONS:
            return <TruckLayer api={api} language={language} key={layer} layerIndex={3} />;
        default:
            null;
    }
};

const getBaseLayer = (api, baseLayer, language) => {
    switch (baseLayer) {
        case MAP_TYPE_DEFAULT:
            return <DefaultLayer api={api} language={language} key={baseLayer} />;
        case MAP_TYPE_FLEET_STYLE:
            return <FleetStyleLayer api={api} language={language} key={baseLayer} />;
        case MAP_TYPE_SATELLITE:
            return <SatelliteLayer api={api} language={language} key={baseLayer} />;
        case MAP_TYPE_TERRAIN:
            return <TerrainLayer api={api} language={language} key={baseLayer} />;
        default:
            null;
    }
};

const enhanceChildren = (children, api) =>
    React.Children.map(children, child => {
        if (child) {
            return React.cloneElement(child, {
                api,
            });
        }
    });

const getStyle = (height, width) => {
    const style = {};
    if (height) {
        style.height = height;
    }
    if (width) {
        style.width = width;
    }
    return style;
};

const Map = props => {
    const {
        children,
        credentials,
        width,
        height,
        language,
        center,
        zoom,
        zoomAnimation,
        boundingBox,
        hideClusterSettings,
        hideMapLayerSettings,
        disableMapEvents,
        disableBehavior,
        eventListenerMap,
        mapType,
        mapLayer,
        showScaleBar,
        onMapLayerChange,
        onMapTypeChange,
        onShowClusterChange,
        onZoomIn,
        onZoomOut,
        mapSettingsTooltip,
        mapSettings,
    } = props;

    const mapRef = useRef(null);

    const [isMapInitialized, setMapInitialized] = useState(false);

    const [herePlatform, setHerePlatform] = useState();
    const [api, setApi] = useState();

    const [baseLayer, setBaseLayer] = useState(mapType);
    const [activeLayers, setActiveLayers] = useState(mapLayer);
    const [showCluster, setShowCluster] = useState(props.showCluster);

    useEffect(() => {
        if (!herePlatform) {
            const platform = new H.service.Platform({
                // Note: "apikey" used by Here v3.1 whereas "app_id" and "app_code" are used by the 3.0 API
                apikey: credentials.apikey || credentials.api_key,
                app_id: credentials.app_id,
                app_code: credentials.app_code,
            });
            setHerePlatform(platform);

            const defaultLayers = platform.createDefaultLayers({
                tileSize: TILE_SIZE,
                ppi: window.devicePixelRatio > 1 ? HIGH_RES_PPI : undefined,
                lg: language,
                lg2: 'en',
            });

            const bounds = boundingBox && getBounds(boundingBox);

            const newHereMap = new H.Map(mapRef.current, defaultLayers.raster.normal.map, {
                zoom,
                center,
                bounds,
                // Removed window.devicePixelRatio || 1 since it was zooming out when devicePixelRatio > 1
                pixelRatio: 1,
                // need to be set for better performence of legacy map
                engineType: H.map.render.RenderEngine.EngineType.P2D,
            });

            // Add a resize listener to make sure that the map occupies the whole container
            const resizeListener = addEventListener(
                window,
                'resize',
                throttle(RESIZE_THROTTLE, () => newHereMap.getViewPort().resize())
            );

            const orientationChangeListener = addEventListener(
                window,
                'orientationchange',
                throttle(RESIZE_THROTTLE, () => newHereMap.getViewPort().resize())
            );

            setMapInitialized(true);

            const newHereMapEvents = !disableMapEvents && new H.mapevents.MapEvents(newHereMap);
            const newHereBehavior = !disableMapEvents && !disableBehavior && new H.mapevents.Behavior(newHereMapEvents);

            if (newHereBehavior) {
                newHereBehavior.disable(H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM);
            }

            addEventListenerMap(newHereMap, eventListenerMap, newHereMap);

            const hereUi = H.ui.UI.createDefault(newHereMap, defaultLayers);
            if (hereUi) {
                hereUi.getControl('scalebar').setVisibility(showScaleBar);
                hereUi.getControl('scalebar').setAlignment('bottom-left');
                hereUi.getControl('mapsettings').setVisibility(false);
                hereUi.removeControl('zoom');
            }

            const newSettings = {
                baseLayer: baseLayer,
                mapLayer: activeLayers,
                showCluster: showCluster,
            };

            setApi({
                credentials,
                defaultLayers,
                group: newHereMap,
                map: newHereMap,
                mapEvents: newHereMapEvents,
                behavior: newHereBehavior,
                platform,
                ui: hereUi,
                settings: newSettings,
                utils: createUtils(newHereMap),
            });

            return () => {
                if (newHereMap) {
                    removeEventListenerMap(newHereMap);
                    if (newHereMapEvents) {
                        newHereMapEvents.dispose();
                    }
                    if (resizeListener) {
                        resizeListener.remove();
                    }
                    if (orientationChangeListener) {
                        orientationChangeListener.remove();
                    }
                    //console.log('Destroy map', newHereMap);
                    newHereMap.dispose();
                    // setTimeout(() => newHereMap.dispose(), 0);
                }
            };
        }
    }, []);

    useEffect(() => {
        if (api && boundingBox) {
            const { top, left, bottom, right } = boundingBox;
            api.map.getViewModel().setLookAtData({ bounds: new H.geo.Rect(top, left, bottom, right) }, zoomAnimation);
        }
    }, [boundingBox]);

    useEffect(() => {
        if (api && zoom) {
            api.map.setZoom(zoom, zoomAnimation);
        }
    }, [zoom]);

    useEffect(() => {
        if (api && center) {
            api.map.setCenter(center, zoomAnimation);
        }
    }, [center]);

    const handleMapTypeChange = newMapType => {
        setBaseLayer(newMapType);
        onMapTypeChange(newMapType, mapType);

        setApi({
            ...api,
            settings: {
                ...api.settings,
                baseLayer: newMapType,
            },
        });
    };

    const handleMapLayerChange = layer => {
        const removeLayer = layerToRemove => activeLayers.filter(item => item !== layerToRemove);
        const newActiveLayers = activeLayers.includes(layer) ? removeLayer(layer) : [...activeLayers, layer];
        setActiveLayers(newActiveLayers);
        onMapLayerChange(newActiveLayers, activeLayers);

        setApi({
            ...api,
            settings: {
                ...api.settings,
                mapLayer: newActiveLayers,
            },
        });
    };

    const handleShowClusterChange = newShowCluster => {
        setShowCluster(newShowCluster);
        onShowClusterChange(newShowCluster, showCluster);

        setApi({
            ...api,
            settings: {
                ...api.settings,
                showCluster: newShowCluster,
            },
        });
    };

    const handleZoomIn = () => {
        const z = api.map.getZoom();
        const newZoom = z + 1;
        onZoomIn(newZoom, z);
        api.map.setZoom(newZoom, true);
    };

    const handleZoomOut = () => {
        const z = api.map.getZoom();
        const newZoom = z - 1;
        onZoomOut(newZoom, z);
        api.map.setZoom(newZoom, true);
    };

    const renderChildren = () => {
        if (!children) {
            return null;
        }

        return (
            <div className={'MapElements'}>{isFunction(children) ? children(api) : enhanceChildren(children, api)}</div>
        );
    };

    const style = getStyle(height, width);
    const autoHeightClass = isEmpty(style) ? 'height-100pct' : '';

    const hideZoomButtons = disableBehavior;

    return (
        <div className={`Map position-relative ${autoHeightClass}`} style={style} ref={mapRef}>
            {isMapInitialized && (
                <MapContext.Provider value={api}>
                    {!hideZoomButtons && <ZoomButtons onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />}
                    {/*!hideMapSettings && !mapSettings && (
                        <MapSettings
                            mapType={baseLayer}
                            mapLayer={activeLayers}
                            showCluster={showCluster}
                            hideMapLayerSettings={hideMapLayerSettings}
                            hideClusterSettings={hideClusterSettings}
                            onMapTypeChange={handleMapTypeChange}
                            onMapLayerChange={handleMapLayerChange}
                            onShowClusterChange={handleShowClusterChange}
                            mapSettingsTooltip={mapSettingsTooltip}
                        />
                    )*/}
                    {mapSettings && (
                        <MapSettingsContext.Provider
                            value={{
                                mapType: baseLayer,
                                activeBuiltinLayers: activeLayers,
                                showCluster: showCluster,
                                hideMapLayerSettings: hideMapLayerSettings,
                                hideClusterSettings: hideClusterSettings,
                                onMapTypeChange: handleMapTypeChange,
                                onMapLayerChange: handleMapLayerChange,
                                onShowClusterChange: handleShowClusterChange,
                                mapSettingsTooltip: mapSettingsTooltip,
                            }}
                        >
                            {mapSettings}
                        </MapSettingsContext.Provider>
                    )}
                    {getBaseLayer(api, baseLayer, language)}
                    {map(layer => getLayer(api, layer, language), activeLayers)}
                    {renderChildren()}
                </MapContext.Provider>
            )}
        </div>
    );
};

Map.TYPE_DEFAULT = MAP_TYPE_DEFAULT;
Map.TYPE_FLEET_STYLE = MAP_TYPE_FLEET_STYLE;
Map.TYPE_SATELLITE = MAP_TYPE_SATELLITE;
Map.TYPE_TERRAIN = MAP_TYPE_TERRAIN;

Map.LAYER_INCIDENTS = MAP_LAYER_INCIDENTS;
Map.LAYER_TRAFFIC = MAP_LAYER_TRAFFIC;
Map.LAYER_ROAD_RESTRICTIONS = MAP_LAYER_ROAD_RESTRICTIONS;

Map.defaultProps = {
    language: 'en',
    zoomAnimation: false,
    hideMapSettings: false,
    hideClusterSettings: false,
    hideMapLayerSettings: false,
    mapType: MAP_TYPE_DEFAULT,
    mapLayer: [],
    showCluster: true,
    showScaleBar: false,
    onMapTypeChange: () => {},
    onMapLayerChange: () => {},
    onShowClusterChange: () => {},
    onZoomIn: () => {},
    onZoomOut: () => {},
};

Map.propTypes = {
    credentials: PropTypes.shape({
        ['app_id']: PropTypes.string,
        ['app_code']: PropTypes.string,
        ['api_key']: PropTypes.string,
        apikey: PropTypes.string,
    }).isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    language: PropTypes.string,
    zoom: PropTypes.number,
    center: mapProps.position,
    boundingBox: mapProps.rect,
    disableMapEvents: PropTypes.bool,
    disableBehavior: PropTypes.bool,
    eventListenerMap: PropTypes.object,
    hideMapSettings: PropTypes.bool,
    hideClusterSettings: PropTypes.bool,
    hideMapLayerSettings: PropTypes.bool,
    mapType: PropTypes.oneOf([Map.TYPE_DEFAULT, Map.TYPE_FLEET_STYLE, Map.TYPE_SATELLITE, Map.TYPE_TERRAIN]),
    mapLayer: PropTypes.arrayOf(PropTypes.string),
    showCluster: PropTypes.bool,
    showScaleBar: PropTypes.bool,
    onMapTypeChange: PropTypes.func,
    onMapLayerChange: PropTypes.func,
    onShowClusterChange: PropTypes.func,
    onZoomIn: PropTypes.func,
    onZoomOut: PropTypes.func,
    getApi: PropTypes.func,
    mapSettingsTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    mapSettings: PropTypes.node,
};

export default Map;
