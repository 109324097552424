import React from 'react';
import PropTypes from 'prop-types';

import MapSettingsItem from '../MapSettingsItem';

import { MapIcon, SYSTEM_POIS_ACTIVE, SYSTEM_POIS_INACTIVE } from '../../../../icons/MapIcon';

const MapCustomerPoiSettingsItem = props => {
    const { isActive, onToggle, ...remainingProps } = props;

    const handleToggle = setIsShown => onToggle(setIsShown);

    return (
        <MapSettingsItem
            className='MapCustomerPoi'
            onClick={() => handleToggle(false)}
            isActive={isActive}
            activeIcon={<MapIcon name={SYSTEM_POIS_ACTIVE} className='MapPoiIcon' />}
            inactiveIcon={<MapIcon name={SYSTEM_POIS_INACTIVE} className='MapPoiIconInactive' />}
            {...remainingProps}
        />
    );
};

MapCustomerPoiSettingsItem.displayName = 'MapCustomerPoiSettingsItem';

MapCustomerPoiSettingsItem.defaultProps = {
    isActive: false,
    onToggle: () => {},
};

MapCustomerPoiSettingsItem.propTypes = {
    isActive: PropTypes.bool,
    onToggle: PropTypes.func,
};

export default MapCustomerPoiSettingsItem;
