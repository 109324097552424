import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import union from 'lodash/fp/union';
import uniqueId from 'lodash/fp/uniqueId';

import { getKeyOrDefault } from '../../../../utils/validation';
import MapSettingsPanel from '../MapSettingsPanel';
import MapSettingsTile from '../MapSettingsTile';

import { MapIcon, LAYER_ACTIVE, LAYER_INACTIVE } from '../../../../icons/MapIcon';

const MapLayerSettings = props => {
    const { activeBuiltinLayers, activeLayers, onMapLayerChange, layers, ...remainingProps } = props;

    const allActiveLayer = union(activeBuiltinLayers, activeLayers);

    return (
        <MapSettingsTile
            className='MapLayerSettings'
            isActive={!isEmpty(allActiveLayer)}
            activeIcon={<MapIcon name={LAYER_ACTIVE} className='MapLayerIcon' />}
            inactiveIcon={<MapIcon name={LAYER_INACTIVE} className='MapLayerIconInactive' />}
            panel={
                <MapSettingsPanel>
                    {map(
                        layer =>
                            React.cloneElement(layer, {
                                key: getKeyOrDefault(uniqueId('MapLayerSettings_'), layer),
                                activeBuiltinLayers,
                                onMapLayerChange,
                            }),
                        layers
                    )}
                </MapSettingsPanel>
            }
            {...remainingProps}
        />
    );
};

MapLayerSettings.displayName = 'MapLayerSettings';

MapLayerSettings.defaultProps = {
    onMapLayerChange: () => {},
    activeBuiltinLayers: [],
    activeLayers: [],
    layers: [],
};

MapLayerSettings.propTypes = {
    onMapLayerChange: PropTypes.func,
    activeBuiltinLayers: PropTypes.arrayOf(PropTypes.string),
    activeLayers: PropTypes.arrayOf(PropTypes.string),
    layers: PropTypes.array,
};

export default MapLayerSettings;
