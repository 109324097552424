/* eslint-disable no-undef */
import { useContext, useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import mapProps from '../../../../utils/proptypes';
import { MapContext } from '../../../context';
import { useUpdateProp, useUpdateEventListenerMap } from '../../../../utils/hooks';

const DEFAULT_DATA_CONVERTER = d => new H.clustering.DataPoint(d.lat, d.lng, 1, d);

const convertData = (data, converterFunc) => data.map(converterFunc ? converterFunc : DEFAULT_DATA_CONVERTER);

const DEFAULT_CLUSTER_THEME = new H.clustering.Provider([]).getTheme();

const ClusterLayer = ({
    data,
    dataConverter,
    minWeight,
    minZoom,
    maxZoom,
    clusterTheme = DEFAULT_CLUSTER_THEME,
    eventListenerMap,
}) => {
    const context = useContext(MapContext);

    const [provider, setProvider] = useState();

    useEffect(() => {
        const newProviderOptions = {
            clusteringOptions: {
                strategy: H.clustering.Provider.Strategy.DYNAMICGRID,
                eps: 100,
                minWeight: minWeight ? parseInt(minWeight, 10) : 2,
            },
            min: minZoom ? parseInt(minZoom, 10) : 0,
            max: maxZoom ? parseInt(maxZoom, 10) : 22,
            // Note: set cluster theme every time the provider changes but leave it out of the hook dependency
            // to avoid recreation of the provider and it's items
            theme: clusterTheme,
        };

        const newProvider = new H.clustering.Provider(convertData(data, dataConverter), newProviderOptions);
        const objectLayer = new H.map.layer.ObjectLayer(newProvider);
        context.map.addLayer(objectLayer);
        setProvider(newProvider);

        return () => {
            context.map.removeLayer(objectLayer);
            objectLayer.dispose();
            newProvider.dispose();
        };
    }, [minWeight, minZoom, maxZoom]);

    // Effect to update data points of provider
    useLayoutEffect(() => {
        if (provider) {
            provider.setDataPoints(convertData(data, dataConverter));
        }
    }, [data, dataConverter]);

    useUpdateProp(provider, 'setTheme', clusterTheme);
    useUpdateEventListenerMap(provider, eventListenerMap, context.map);

    return null;
};

ClusterLayer.propTpyes = {
    data: PropTypes.array.isRequired,
    dataConverter: PropTypes.func,
    minWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minZoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxZoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    clusterTheme: mapProps.clusterTheme,
    eventListenerMap: PropTypes.object,
};

export default ClusterLayer;
