import React from 'react';
import PropTypes from 'prop-types';
import { default as _OverlayTrigger } from 'react-bootstrap/OverlayTrigger';

export default function OverlayTrigger(props) {
    const { rootClose, ...remainingProps } = props;
    return <_OverlayTrigger rootClose={rootClose} {...remainingProps} />;
}

OverlayTrigger.TRIGGER_CLICK = 'click';
OverlayTrigger.TRIGGER_HOVER = 'hover';
OverlayTrigger.TRIGGER_FOCUS = 'focus';

export const triggerType = PropTypes.oneOf([
    OverlayTrigger.TRIGGER_CLICK,
    OverlayTrigger.TRIGGER_HOVER,
    OverlayTrigger.TRIGGER_FOCUS,
]);

OverlayTrigger.AUTO_START = 'auto-start';
OverlayTrigger.AUTO = 'auto';
OverlayTrigger.AUTO_END = 'auto-end';

OverlayTrigger.TOP_START = 'top-start';
OverlayTrigger.TOP = 'top';
OverlayTrigger.TOP_END = 'top-end';

OverlayTrigger.BOTTOM_START = 'bottom-start';
OverlayTrigger.BOTTOM = 'bottom';
OverlayTrigger.BOTTOM_END = 'bottom-end';

OverlayTrigger.RIGHT_START = 'right-start';
OverlayTrigger.RIGHT = 'right';
OverlayTrigger.RIGHT_END = 'right-end';

OverlayTrigger.LEFT_START = 'left-start';
OverlayTrigger.LEFT = 'left';
OverlayTrigger.LEFT_END = 'left-end';

export const OverlayTriggerPlacementType = PropTypes.oneOf([
    OverlayTrigger.AUTO_START,
    OverlayTrigger.AUTO,
    OverlayTrigger.AUTO_END,
    OverlayTrigger.TOP_START,
    OverlayTrigger.TOP,
    OverlayTrigger.TOP_END,
    OverlayTrigger.BOTTOM_START,
    OverlayTrigger.BOTTOM,
    OverlayTrigger.BOTTOM_END,
    OverlayTrigger.RIGHT_START,
    OverlayTrigger.RIGHT,
    OverlayTrigger.RIGHT_END,
    OverlayTrigger.LEFT_START,
    OverlayTrigger.LEFT,
    OverlayTrigger.LEFT_END,
]);

OverlayTrigger.defaultProps = {
    rootClose: true,
};

OverlayTrigger.propTypes = {
    children: PropTypes.element.isRequired,
    trigger: PropTypes.oneOfType([triggerType, PropTypes.arrayOf(triggerType)]),
    delay: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            show: PropTypes.number,
            hide: PropTypes.number,
        }),
    ]),
    defaultShow: PropTypes.bool,
    onToggle: PropTypes.func,
    overlay: PropTypes.oneOfType([PropTypes.func, PropTypes.element.isRequired]),
    popperConfig: PropTypes.object,
    placement: OverlayTriggerPlacementType,
};
