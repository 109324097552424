import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import getOr from 'lodash/fp/getOr';

import mapProps from '../../../utils/proptypes';
import ClusterLayer from './clustering/ClusterLayer';
import { wrapSimpleTheme } from '../../../utils/clustering';
import { MapContext } from '../../context';

/*
 * Not very different to the SimpleClusterLayer, but with a different intention.
 */
const MarkerLayer = props => {
    const context = useContext(MapContext);

    const { simpleTheme, clusterDissolvable = true, ...rest } = props;
    // Norbert: Not an ideal solution
    const showCluster = !clusterDissolvable || getOr(true, 'settings.showCluster', context);
    const newProps = {
        clusterTheme: simpleTheme && wrapSimpleTheme(simpleTheme),
        ...rest,
    };
    return <ClusterLayer {...newProps} minWeight={showCluster ? undefined : 1000000000} />;
};

MarkerLayer.propTypes = {
    // api: mapProps.api,
    simpleTheme: mapProps.clusterTheme,
    clusterDissolvable: PropTypes.bool,
};

export default MarkerLayer;
