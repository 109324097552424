import { useEffect, useContext } from 'react';
import { MapContext } from '../components/context';
import { addEventListenerMap, removeEventListenerMap } from './eventHandling';

export const useUpdateProp = (mapObject, propSetter, value) => {
    useEffect(() => {
        if (mapObject) {
            mapObject[propSetter](value);
        }
    }, [value]);
};

export const useUpdateEventListenerMap = (mapObject, eventListenerMap) => {
    const { map } = useContext(MapContext);
    useEffect(() => {
        if (!mapObject) {
            return;
        }
        addEventListenerMap(mapObject, eventListenerMap, map);
        return () => {
            removeEventListenerMap(mapObject);
        };
    }, [eventListenerMap, mapObject, map]);
};
