/* eslint-disable no-undef */
import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import mapProps from '../../../utils/proptypes';
import { MapContext } from '../../context';
import { useUpdateProp, useUpdateEventListenerMap } from '../../../utils/hooks';

const Circle = ({ position, radius = 100, precision = 60, style, eventListenerMap }) => {
    const context = useContext(MapContext);
    const [circle, setCircle] = useState();

    useEffect(() => {
        const newCircle = new H.map.Circle(position, radius, { style, precision: parseFloat(precision) });
        setCircle(newCircle);
        context.group.addObject(newCircle);
        return () => {
            if (context.group.getObjects().includes(newCircle)) {
                context.group.removeObject(newCircle);
            }
            newCircle.dispose();
        };
    }, []);

    useUpdateProp(circle, 'setCenter', position);
    useUpdateProp(circle, 'setStyle', style);
    useUpdateProp(circle, 'setRadius', radius);
    useUpdateProp(circle, 'setPrecision', precision);

    useUpdateEventListenerMap(circle, eventListenerMap);

    return null;
};

Circle.propTypes = {
    position: mapProps.position.isRequired,
    eventListenerMap: PropTypes.object,
    style: PropTypes.object,
    radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    precision: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Circle;
