import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import getOr from 'lodash/fp/getOr';

import OverlayTrigger from '../../../../overlay/OverlayTrigger';
import Tooltip from '../../../../tooltip/Tooltip';

const getComponentWithTooltip = (component, tooltip) => {
    return (
        <OverlayTrigger
            placement={Tooltip.TOP}
            delayShow={1000}
            overlay={
                <Tooltip id={'tooltip'} width={'auto'}>
                    {tooltip}
                </Tooltip>
            }
        >
            {component}
        </OverlayTrigger>
    );
};

const MapSettingsTile = props => {
    const {
        onToggle,
        activeIcon,
        inactiveIcon,
        isActive,
        panel,
        className,
        buttonClassName,
        tooltip,
        ...remainingProps
    } = props;

    const buttonRef = createRef();
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        const newState = !isOpen;
        setIsOpen(newState);
        onToggle(newState);

        buttonRef.current.blur();
    };

    const wrapperClasses = classNames('MapSettingsTile', className);

    const buttonClasses = classNames('MapSettingsButton', buttonClassName);

    const activeIconClassNames = classNames(
        'hover-scale-110',
        isActive ? 'display-block' : 'display-none',
        getOr('', 'props.className')(activeIcon)
    );

    const inactiveIconClassNames = classNames(
        'hover-scale-110',
        isActive ? 'display-none' : 'display-block',
        getOr('', 'props.className')(inactiveIcon)
    );

    const button = (
        <div className={buttonClasses} onClick={handleToggle}>
            {activeIcon &&
                React.cloneElement(activeIcon, {
                    className: activeIconClassNames,
                })}
            {inactiveIcon &&
                React.cloneElement(inactiveIcon, {
                    className: inactiveIconClassNames,
                })}
        </div>
    );

    return (
        <div className={wrapperClasses} height={40} width={40} ref={buttonRef} {...remainingProps}>
            {tooltip ? getComponentWithTooltip(button, tooltip) : button}
            {isOpen && panel && panel}
        </div>
    );
};

MapSettingsTile.defaultProps = {
    className: '',
    buttonClassName: '',
    onToggle: () => {},
};

MapSettingsTile.propTypes = {
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    onToggle: PropTypes.func,
    tooltip: PropTypes.node,
    panel: PropTypes.node,
};

export default MapSettingsTile;
