import React from 'react';
import PropTypes from 'prop-types';

import { MAP_LAYER_TRAFFIC } from '../../../constants';
import MapSettingsItem from '../MapSettingsItem';

import { MapIcon, TRAFFIC_ACTIVE, TRAFFIC_INACTIVE } from '../../../../icons/MapIcon';

const MapLayerTrafficItem = props => {
    const { activeBuiltinLayers, onMapLayerChange, onToggle, ...remainingProps } = props;

    const handleToggle = () => {
        onMapLayerChange(MAP_LAYER_TRAFFIC);
        onToggle();
    };

    return (
        <MapSettingsItem
            className='MapLayerTraffic'
            onClick={handleToggle}
            isActive={activeBuiltinLayers.includes(MAP_LAYER_TRAFFIC)}
            activeIcon={<MapIcon name={TRAFFIC_ACTIVE} />}
            inactiveIcon={<MapIcon name={TRAFFIC_INACTIVE} />}
            {...remainingProps}
        />
    );
};

MapLayerTrafficItem.displayName = 'MapLayerTrafficItem';

MapLayerTrafficItem.defaultProps = {
    activeBuiltinLayers: [],
    onMapLayerChange: () => {},
    onToggle: () => {},
};

MapLayerTrafficItem.propTypes = {
    activeBuiltinLayers: PropTypes.array,
    onMapLayerChange: PropTypes.func,
    onToggle: PropTypes.func,
};

export default MapLayerTrafficItem;
