import React from 'react';
import PropTypes from 'prop-types';

import MapSettingsTile from '../MapSettingsTile';
import { MapIcon, LOCK_ACTIVE, LOCK_INACTIVE } from '../../../../icons/MapIcon';

const MapLockMarkerButton = props => {
    const { isActive, onToggle, ...remainingProps } = props;

    return (
        <MapSettingsTile
            className='MapLockMarkerButton'
            isActive={isActive}
            activeIcon={<MapIcon name={LOCK_ACTIVE} className='MapLockMarkerButtonIcon' />}
            inactiveIcon={<MapIcon name={LOCK_INACTIVE} className='MapLockMarkerButtonIconInactive' />}
            onToggle={onToggle}
            {...remainingProps}
        />
    );
};

MapLockMarkerButton.displayName = 'MapLockMarkerButton';

MapLockMarkerButton.defaultProps = {
    isActive: false,
};

MapLockMarkerButton.propTypes = {
    onToggle: PropTypes.func,
    isActive: PropTypes.bool,
};

export default MapLockMarkerButton;
