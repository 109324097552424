/* eslint-disable no-undef */
import PropTypes from 'prop-types';

const position = PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
});

const positions = PropTypes.arrayOf(position);

const hMapIcon = PropTypes.instanceOf(H.map.Icon);

const hMapDomIcon = PropTypes.instanceOf(H.map.DomIcon);

const rect = PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
});

const icon = PropTypes.oneOfType([hMapIcon, hMapDomIcon, PropTypes.string, PropTypes.element]);

const map = PropTypes.instanceOf(H.Map);

const group = PropTypes.instanceOf(H.map.Group);

const mapOrGroup = PropTypes.oneOfType([map, group]);

const platform = PropTypes.instanceOf(H.service.Platform);

const mapEvents = PropTypes.instanceOf(H.mapevents.MapEvents);

const defaultLayers = PropTypes.object;

const api = PropTypes.shape({
    map,
    platform,
    defaultLayers,
    group: mapOrGroup,
});

const settings = PropTypes.shape({
    baseLayer: PropTypes.string,
    mapLayer: PropTypes.arrayOf(PropTypes.string),
    showCluster: PropTypes.bool,
});

const clusterTheme = PropTypes.shape({
    getNoisePresentation: PropTypes.func.isRequired,
    getClusterPresentation: PropTypes.func.isRequired,
});

const mapProps = {
    position,
    positions,
    icon,
    map,
    group,
    mapEvents,
    api,
    settings,
    rect,
    clusterTheme,
};

export default mapProps;
