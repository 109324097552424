const BoundingBox = () => {
    this.top = -Infinity;
    this.right = -Infinity;
    this.bottom = Infinity;
    this.left = Infinity;
};

BoundingBox.prototype = {
    isValid: () => {
        return this.top !== -Infinity && this.bottom !== Infinity && this.left !== Infinity && this.right !== -Infinity;
    },
};

const calculateBoundingBox = positions => {
    return positions.reduce((acc, cur) => {
        if (cur.lat > acc.top) {
            acc.top = cur.lat;
        }
        if (cur.lat < acc.bottom) {
            acc.bottom = cur.lat;
        }
        if (cur.lng < acc.left) {
            acc.left = cur.lng;
        }
        if (cur.lng > acc.right) {
            acc.right = cur.lng;
        }
        return acc;
    }, new BoundingBox());
};

const COORDS_REGEX = /^\s*(-?\d+(\.\d+)?)\s*[,\s]\s*(-?\d+(\.\d+)?)\s*$/;

const parsePositionFromString = value => {
    const match = COORDS_REGEX.exec(value);
    if (match) {
        const lat = parseFloat(match[1]);
        const lng = parseFloat(match[3]);
        if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
            return {
                lat,
                lng,
            };
        }
    }
};

export const PositionUtils = {
    calculateBoundingBox,
    parsePositionFromString,
};
