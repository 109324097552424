import React from 'react';
import PropTypes from 'prop-types';

import MapSettingsTile from '../MapSettingsTile';

import { MapIcon, BOUNDING_BOX_ACTIVE, BOUNDING_BOX_INACTIVE } from '../../../../icons/MapIcon';

const MapBoundingBoxButton = props => {
    const { isActive, onToggle, ...remainingProps } = props;

    return (
        <MapSettingsTile
            className='MapBoundingBoxButton'
            isActive={isActive}
            activeIcon={<MapIcon name={BOUNDING_BOX_ACTIVE} className='MapBoundingBoxButtonIcon' />}
            inactiveIcon={<MapIcon name={BOUNDING_BOX_INACTIVE} className='MapBoundingBoxButtonIconInactive' />}
            onToggle={onToggle}
            {...remainingProps}
        />
    );
};

MapBoundingBoxButton.displayName = 'MapBoundingBoxButton';

MapBoundingBoxButton.defaultProps = {
    isActive: false,
};

MapBoundingBoxButton.propTypes = {
    onToggle: PropTypes.func,
    isActive: PropTypes.bool,
};

export default MapBoundingBoxButton;
